import { ServiceChooseCarCenterFormFields } from './components/ServiceFormView/components/ServiceChooseCarCenterForm/types'
import { ServiceChooseCarFormFields } from './components/ServiceFormView/components/ServiceChooseCarForm/types'

import { ServiceChooseProgramTOFields } from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceChooseProgramTOForm/types'
import { ServiceSummaryFormFields } from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceSummaryForm/types'
import { ServiceChooseServiceFields } from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceChooseServiceForm/types'

export enum ServiceFormState {
  Service = 'Выберите вид работ',
  Car = 'Выберите автомобиль',
  ProgramTO = 'Выберите услугу',
  CarCenter = 'Выберите автоцентр',
  Summary = 'Итого',
}

export type ServiceFormViewProps =
  | {
      state: ServiceFormState.Service
      props: {
        onNextStep: (values: ServiceChooseServiceFields) => void
      }
    }
  | {
      state: ServiceFormState.Car
      props: {
        onPreviousStep: () => void
        onNextStep: (values: ServiceChooseCarFormFields) => void
      }
    }
  | {
      state: ServiceFormState.CarCenter
      props: {
        onNextStep: (values: ServiceChooseCarCenterFormFields) => void
        onPreviousStep: () => void
      }
    }
  | {
      state: ServiceFormState.ProgramTO
      props: {
        onNextStep: (values: ServiceChooseProgramTOFields) => void
        onPreviousStep: () => void
      }
    }
  | {
      state: ServiceFormState.Summary
      props: {
        onNextStep: (values: ServiceSummaryFormFields) => Promise<void>
        onPreviousStep: () => void
      }
    }
