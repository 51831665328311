import { ApiService } from '@/services/api'
import { CatalogBrandsAndModelsResponse } from '@/services/catalog/typing'

const request = new ApiService({
  url: process.env.REACT_APP_CATALOG_SERVICE_URL,
}).getRequest()

export const getBrandsAndModelsData = (): Promise<
  CatalogBrandsAndModelsResponse[]
> => {
  return request.get({
    path: '/brands-and-models',
  })
}
