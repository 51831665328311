import { ServiceChooseCarFormFields } from './types'

import { ServiceCarsPayload } from '@/services/users/typings'
import { Nullable, OptionType } from '@/typings/common'
import { DefaultOption, getSelectOption } from '@/modules/PersonalData/utils'
import { CatalogBrandsAndModelsResponse } from '@/services/catalog/typing'
import {
  IdNameType,
  ServiceOnlineBrandsResponseType,
} from '@/services/service/typing'
import {
  NO_ITEM_MAP,
  NoItemMapKeyType,
  SELECT_VALUE_ALL,
} from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceChooseCarForm/constants'

export const getCarOptions = (cars: ServiceCarsPayload[]): OptionType[] => {
  const helpOptions = [{ value: 'Ввести вручную', label: 'Ввести вручную' }]

  if (!cars || cars.length === 0) {
    return helpOptions
  }

  const carsOptions = cars.map(({ name }) => {
    return {
      value: name ?? '',
      label: name ?? '',
    }
  })

  return [...helpOptions, ...carsOptions]
}

export const getCarId = (
  cars: ServiceCarsPayload[],
  searchCar: string,
): number => {
  if (
    !cars ||
    cars.length === 0 ||
    searchCar === 'Выберите ваше авто' ||
    searchCar === 'Ввести вручную'
  ) {
    return 0
  }

  const foundCar = cars.find(car => car.name === searchCar)

  return foundCar?.id ?? 0
}

export const getCarIdByName = (
  brands: CatalogBrandsAndModelsResponse[],
  searchBrandName: string,
): number => {
  if (!brands || brands.length === 0) {
    return 0
  }

  if (searchBrandName === 'Zeekr') {
    searchBrandName = 'Zeekr EU'
  }

  const foundBrand = brands.find(brand => brand.name === searchBrandName)

  return foundBrand?.id ?? 0
}

export const getInitialActiveBrandOption = (
  chooseCarData: Nullable<ServiceChooseCarFormFields>,
  brands: CatalogBrandsAndModelsResponse[],
): OptionType => {
  if (!chooseCarData) return DefaultOption

  const foundBrand = brands.find(
    brand => brand.name === chooseCarData?.carBrandName,
  )

  return { label: foundBrand?.name ?? '', value: String(foundBrand?.id) ?? '' }
}

export const getInitialCarOption = (
  chooseCarData: Nullable<ServiceChooseCarFormFields>,
  cars: ServiceCarsPayload[],
): OptionType => {
  if (chooseCarData?.carId) {
    const foundCar = cars.find(car => car.id === chooseCarData.carId)

    return { value: foundCar?.name ?? '', label: foundCar?.name ?? '' }
  } else if (chooseCarData?.carBrand || chooseCarData?.carBrand === 0) {
    return { value: 'Ввести вручную', label: 'Ввести вручную' }
  } else if (cars.length) {
    return { value: cars[0].name ?? '', label: cars[0].name ?? '' }
  } else return { value: 'Ввести вручную', label: 'Ввести вручную' }
}

export const getAllBrands = (
  brands: ServiceOnlineBrandsResponseType[],
): OptionType[] => {
  return brands.map(brand => ({
    value: String(brand.id),
    label: brand.name,
  }))
}

export const setResponse = (
  type: NoItemMapKeyType,
  items: IdNameType[],
  setActiveItem: (value: OptionType) => void,
  setItems: (value: OptionType[]) => void,
): void => {
  const additionalItem = NO_ITEM_MAP[type]

  if (items.length === 1 && items[0].id === SELECT_VALUE_ALL.value) {
    setActiveItem({ ...SELECT_VALUE_ALL })
  } else {
    setItems(getSelectOption([...items, additionalItem]))
  }
}

export const getConvertBrandsResponse = (
  brands: ServiceOnlineBrandsResponseType[],
): ServiceOnlineBrandsResponseType[] => {
  return brands
    .filter(brand => brand.name !== 'Zeekr CN')
    .map(brand =>
      brand.name === 'Zeekr EU'
        ? {
            ...brand,
            name: 'Zeekr',
          }
        : brand,
    )
}
