import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import {
  initialServiceChooseCarFormValues,
  validationServiceChooseCarAutoFormSchema,
  validationServiceChooseCarByHandFormSchema,
} from './ServiceChooseCarForm.helpers'
import { ServiceChooseCarFormFields, ServiceChooseCarFormProps } from './types'
import {
  getAllBrands,
  getCarId,
  getCarIdByName,
  getCarOptions,
  getConvertBrandsResponse,
  getInitialActiveBrandOption,
  getInitialCarOption,
  setResponse,
} from './utils'

import { OptionType } from '@/typings/common'
import {
  DefaultOption,
  getCarById,
  getInitialOptionSelect,
  getSelectOption,
} from '@/modules/PersonalData/utils'
import CabinetFormTemplate from '@/components/PersonalCabinetForms/components/CabinetFormTemplate'
import {
  FormAction,
  FormActionCol,
  FormCol,
  FormRow,
} from '@/components/PersonalCabinetForms/components/CabinetForm.style'
import CabinetFormField from '@/components/PersonalCabinetForms/components/CabinetFormField'
import CabinetSelect from '@/components/PersonalCabinetForms/components/CabinetSelect'
import CabinetTextInput from '@/components/PersonalCabinetForms/components/CabinetTextInput'
import CabinetButton from '@/components/PersonalCabinetForms/components/CabinetButton'
import { ServiceOnlineBrandsResponseType } from '@/services/service/typing'
import { useProfile } from '@/providers/Profile'
import { useBrandsAndModelsOptions } from '@/hooks/useBrandsAndModelsOptions'
import {
  NO_ITEM_MAP,
  OFFLINE_REQUEST,
  SELECT_VALUE_ALL,
} from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceChooseCarForm/constants'
import {
  getServiceBrands,
  getServiceDrives,
  getServiceEngines,
  getServiceGearboxes,
  getServiceModels,
  getServiceYears,
} from '@/services/service/service-service'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import Spinner from '@/components/Spinner'
import { FormInnerWrapper } from '@/modules/Service/components/MainContent/components/ServiceFormView/components/common.styles'
import { media } from '@/utils/mixin'

function ServiceChooseCarForm({
  onNextStep,
  onPreviousStep,
}: ServiceChooseCarFormProps) {
  const { service, brandsAndModels } = useProfile()
  const { chooseCarData, cars, chooseService } = service

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [allBrands, setAllBrands] = useState<ServiceOnlineBrandsResponseType[]>(
    [],
  )

  const [models, setModels] = useState<OptionType[]>([DefaultOption])
  const [engines, setEngines] = useState<OptionType[]>([])
  const [years, setYears] = useState<OptionType[]>([])
  const [gearboxes, setGearboxes] = useState<OptionType[]>([])
  const [drives, setDrives] = useState<OptionType[]>([])

  const [activeCarOption, setActiveCarOption] = useState<OptionType>(
    getInitialCarOption(chooseCarData, cars),
  )
  const [activeBrandOption, setActiveBrandOption] = useState<OptionType>(
    getInitialActiveBrandOption(chooseCarData, brandsAndModels.data),
  )
  const [activeModel, setActiveModel] = useState<OptionType>(
    getInitialOptionSelect(chooseCarData?.carModel),
  )
  const [activeEngine, setActiveEngine] = useState<OptionType>(
    getInitialOptionSelect(chooseCarData?.engine),
  )
  const [activeYear, setActiveYear] = useState<OptionType>(
    getInitialOptionSelect(chooseCarData?.year),
  )
  const [activeGearbox, setActiveGearbox] = useState<OptionType>(
    getInitialOptionSelect(chooseCarData?.gearbox),
  )
  const [activeWheel, setActiveWheel] = useState<OptionType>(
    getInitialOptionSelect(chooseCarData?.wheel),
  )

  const isManualInput = activeCarOption.value === 'Ввести вручную'
  const isChooseTO = chooseService?.service.label === 'Техническое обслуживание'

  const options = useBrandsAndModelsOptions(activeBrandOption, true)
  const carsOptions = getCarOptions(cars)
  const brandsOptions = isChooseTO ? getAllBrands(allBrands) : options?.brands
  const modelsOptions = isChooseTO ? models : options?.models

  const {
    values,
    errors,
    setFieldValue,
    touched,
    handleChange,
    setValues,
    handleSubmit,
    resetForm,
  } = useFormik<ServiceChooseCarFormFields>({
    initialValues: initialServiceChooseCarFormValues,
    validationSchema: !isManualInput
      ? validationServiceChooseCarAutoFormSchema
      : validationServiceChooseCarByHandFormSchema(isChooseTO),
    onSubmit: values => {
      const isOfflineRequest = Object.values(values).some(
        el => el?.value === OFFLINE_REQUEST,
      )

      const additionalParams = {
        carBrand: values.carBrand,
        availableOnlineOrder: isOfflineRequest
          ? false
          : allBrands.find(brand => brand.id === values?.carBrand)
              ?.availableOnlineOrder,
        carBrandName: values.carId
          ? values.carBrandName
          : activeBrandOption?.label,
      }

      onNextStep({
        ...values,
        ...additionalParams,
      })
    },
  })

  useEffect(() => {
    if (chooseCarData) {
      setValues(chooseCarData)
      setActiveBrandOption({
        value: String(chooseCarData.carBrand),
        label: chooseCarData.carBrandName,
      })

      if (isChooseTO && !chooseCarData.carId && chooseCarData.carBrand) {
        ;(async () => {
          const { gearbox, engine, year, carModel, carBrand } = chooseCarData

          try {
            setIsLoading(true)

            const modelsResponse = await getServiceModels({ brand: carBrand })

            setModels(
              getSelectOption([...modelsResponse, NO_ITEM_MAP['model']]),
            )

            const engineResponse =
              chooseCarData.carModel?.value !== OFFLINE_REQUEST &&
              chooseCarData.carModel?.value
                ? await getServiceEngines({
                    brand: carBrand,
                    model: carModel?.value ?? '',
                  })
                : { items: [] }

            chooseCarData.engine?.value !== SELECT_VALUE_ALL.value &&
              setEngines(
                getSelectOption([
                  ...engineResponse.items,
                  NO_ITEM_MAP['engine'],
                ]),
              )

            const yearsResponse =
              chooseCarData.engine?.value !== OFFLINE_REQUEST &&
              chooseCarData.engine?.value
                ? await getServiceYears({
                    brand: carBrand,
                    model: carModel?.value ?? '',
                    engine: engine?.value ?? '',
                  })
                : { items: [] }

            chooseCarData.year?.value !== SELECT_VALUE_ALL.value &&
              setYears(
                getSelectOption([...yearsResponse.items, NO_ITEM_MAP['year']]),
              )

            const gearboxesResponse =
              chooseCarData.year?.value !== OFFLINE_REQUEST &&
              chooseCarData.year?.value
                ? await getServiceGearboxes({
                    brand: carBrand,
                    model: carModel?.value ?? '',
                    engine: engine?.value ?? '',
                    year: year?.value ?? '',
                  })
                : { items: [] }

            chooseCarData.gearbox?.value !== SELECT_VALUE_ALL.value &&
              setGearboxes(
                getSelectOption([
                  ...gearboxesResponse.items,
                  NO_ITEM_MAP['gearbox'],
                ]),
              )

            const drivesResponse =
              chooseCarData.gearbox?.value !== OFFLINE_REQUEST &&
              chooseCarData.gearbox?.value
                ? await getServiceDrives({
                    brand: carBrand,
                    model: carModel?.value ?? '',
                    engine: engine?.value ?? '',
                    year: year?.value ?? '',
                    gearbox: gearbox?.value ?? '',
                  })
                : { items: [] }

            chooseCarData.wheel?.value !== SELECT_VALUE_ALL.value &&
              setDrives(
                getSelectOption([
                  ...drivesResponse.items,
                  NO_ITEM_MAP['wheel'],
                ]),
              )
          } catch (error) {
            console.error(error)
          } finally {
            setIsLoading(false)
          }
        })()
      }
    } else if (!isManualInput) {
      setChooseDataFormik()
    }

    if (isChooseTO) {
      ;(async () => {
        try {
          setIsLoading(true)
          const brandsResponse = await getServiceBrands({
            productId: Number(chooseService?.service.value) ?? 0,
          })

          setAllBrands(getConvertBrandsResponse(brandsResponse))
        } catch (error) {
          console.log(error)
        } finally {
          setIsLoading(false)
        }
      })()
    }
  }, [])
  useUpdateEffect(() => {
    if (getCarId(cars, activeCarOption.value)) {
      setChooseDataFormik()
    } else {
      resetForm()
      setActiveBrandOption(DefaultOption)
      setActiveModel(DefaultOption)
    }
  }, [activeCarOption])

  useUpdateEffect(() => {
    ;(async () => {
      try {
        if (isChooseTO && !values.carId && activeBrandOption.value) {
          setIsLoading(true)

          const modelsResponse = await getServiceModels({
            brand: Number(activeBrandOption.value),
          })

          setModels(getSelectOption([...modelsResponse, NO_ITEM_MAP['model']]))
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [activeBrandOption])
  useUpdateEffect(() => {
    ;(async () => {
      try {
        if (
          isChooseTO &&
          !values.carId &&
          activeModel.value &&
          activeModel.value !== OFFLINE_REQUEST
        ) {
          setIsLoading(true)

          const engineResponse = await getServiceEngines({
            brand: Number(activeBrandOption.value),
            model: activeModel.value,
          })

          setResponse(
            'engine',
            engineResponse.items,
            setActiveEngine,
            setEngines,
          )
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [activeModel])
  useUpdateEffect(() => {
    ;(async () => {
      try {
        if (
          isChooseTO &&
          !values.carId &&
          activeEngine.value &&
          activeEngine.value !== OFFLINE_REQUEST
        ) {
          setIsLoading(true)

          const yearsResponse = await getServiceYears({
            brand: Number(activeBrandOption.value),
            model: activeModel.value,
            engine: activeEngine.value,
          })

          setResponse('year', yearsResponse.items, setActiveYear, setYears)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [activeEngine])
  useUpdateEffect(() => {
    ;(async () => {
      try {
        if (
          isChooseTO &&
          !values.carId &&
          activeYear.value &&
          activeYear.value !== OFFLINE_REQUEST
        ) {
          setIsLoading(true)

          const gearboxResponse = await getServiceGearboxes({
            brand: Number(activeBrandOption.value),
            model: activeModel.value,
            engine: activeEngine.value,
            year: activeYear.value,
          })

          setResponse(
            'gearbox',
            gearboxResponse.items,
            setActiveGearbox,
            setGearboxes,
          )
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [activeYear])
  useUpdateEffect(() => {
    ;(async () => {
      try {
        if (
          isChooseTO &&
          !values.carId &&
          activeGearbox.value &&
          activeGearbox.value !== OFFLINE_REQUEST
        ) {
          setIsLoading(true)

          const driveResponse = await getServiceDrives({
            brand: Number(activeBrandOption.value),
            model: activeModel.value,
            engine: activeEngine.value,
            year: activeYear.value,
            gearbox: activeGearbox.value,
          })

          setResponse('wheel', driveResponse.items, setActiveWheel, setDrives)
        }
      } catch (error) {
      } finally {
        setIsLoading(false)
      }
    })()
  }, [activeGearbox])

  useUpdateEffect(() => {
    if (chooseCarData?.carBrandName !== activeBrandOption.label) {
      setFieldValue(
        'carBrand',
        getCarIdByName(brandsAndModels.data, activeBrandOption.label),
      )

      setActiveModel(DefaultOption)
      setActiveEngine(DefaultOption)
      setActiveYear(DefaultOption)
      setActiveGearbox(DefaultOption)
      setActiveWheel(DefaultOption)
    }
  }, [activeBrandOption])
  useUpdateEffect(() => {
    setFieldValue('carModel', activeModel)

    setActiveEngine(DefaultOption)
    setActiveYear(DefaultOption)
    setActiveGearbox(DefaultOption)
    setActiveWheel(DefaultOption)
  }, [activeModel])
  useUpdateEffect(() => {
    setFieldValue('engine', activeEngine)

    setActiveYear(DefaultOption)
    setActiveGearbox(DefaultOption)
    setActiveWheel(DefaultOption)
  }, [activeEngine])
  useUpdateEffect(() => {
    setFieldValue('year', activeYear)

    setActiveGearbox(DefaultOption)
    setActiveWheel(DefaultOption)
  }, [activeYear])
  useUpdateEffect(() => {
    setFieldValue('gearbox', activeGearbox)

    setActiveWheel(DefaultOption)
  }, [activeGearbox])
  useUpdateEffect(() => {
    setFieldValue('wheel', activeWheel)
  }, [activeWheel])

  const handleChangeCarSelect = (option: OptionType) => {
    if (activeCarOption === option) {
      return
    }

    setActiveCarOption(option)
  }

  const setChooseDataFormik = () => {
    const car = getCarById(getCarId(cars, activeCarOption.value), cars)
    resetForm()
    setFieldValue('carId', car?.id)
    setFieldValue('carBrand', car?.brand)
    setFieldValue('carBrandName', car?.brandName)
    setFieldValue('carModel', { value: car?.modelName, label: car?.modelName })
    setFieldValue('carVin', car?.vin)
    setFieldValue('carPlate', car?.number)
    setFieldValue('mileage', car?.mileage)
  }

  const handleClickHelpMe = () => {
    onNextStep({
      ...initialServiceChooseCarFormValues,
      carBrand: values.carBrand,
      carBrandName: activeBrandOption?.label || values.carBrandName,
    })
  }

  return (
    <Component onSubmit={handleSubmit} noValidate autoComplete='off'>
      {isLoading && <Spinner absolute={true} isCabinet={true} />}

      <FormInnerWrapper isLoading={isLoading}>
        <CabinetFormTemplate title={'Данные автомобиля'}>
          {isManualInput && (
            <Button onClick={handleClickHelpMe}>Нужна помощь?</Button>
          )}

          <FormRow>
            <FormCol>
              <CabinetFormField label={'Ваше авто'}>
                <CabinetSelect
                  value={activeCarOption}
                  options={carsOptions}
                  onChange={handleChangeCarSelect}
                />
              </CabinetFormField>
            </FormCol>
          </FormRow>

          {isManualInput && (
            <React.Fragment>
              <FormRow>
                <FormCol>
                  <CabinetFormField
                    label={'Бренд'}
                    error={
                      errors.carBrand && touched.carBrand
                        ? errors.carBrand
                        : undefined
                    }
                  >
                    <CabinetSelect
                      value={activeBrandOption}
                      options={brandsOptions ?? []}
                      onChange={setActiveBrandOption}
                    />
                  </CabinetFormField>
                </FormCol>

                <FormCol>
                  {values.carBrand !== 0 && (
                    <CabinetFormField
                      label={'Модель'}
                      error={
                        errors.carModel?.label && touched.carModel?.label
                          ? errors.carModel?.label
                          : undefined
                      }
                    >
                      <CabinetSelect
                        value={activeModel}
                        options={modelsOptions ?? []}
                        onChange={setActiveModel}
                      />
                    </CabinetFormField>
                  )}
                </FormCol>
              </FormRow>

              {isChooseTO && (
                <>
                  {values.carModel?.label &&
                    activeModel.label !== NO_ITEM_MAP['model'].name && (
                      <FormRow>
                        <FormCol>
                          <CabinetFormField
                            label={'Объем и тип двигателя'}
                            error={
                              errors.engine?.label && touched.engine?.label
                                ? errors.engine?.label
                                : undefined
                            }
                          >
                            <CabinetSelect
                              value={activeEngine}
                              options={engines}
                              onChange={setActiveEngine}
                              disabled={
                                activeEngine.value === SELECT_VALUE_ALL.value ||
                                engines.length === 0
                              }
                            />
                          </CabinetFormField>
                        </FormCol>
                      </FormRow>
                    )}

                  {values.engine?.label &&
                    activeEngine.label !== NO_ITEM_MAP['engine'].name && (
                      <FormRow>
                        <FormCol>
                          <CabinetFormField
                            label={'Год выпуска'}
                            error={
                              errors.year?.label && touched.year?.label
                                ? errors.year?.label
                                : undefined
                            }
                          >
                            <CabinetSelect
                              value={activeYear}
                              options={years}
                              onChange={setActiveYear}
                              disabled={
                                activeYear.value === SELECT_VALUE_ALL.value ||
                                years.length === 0
                              }
                            />
                          </CabinetFormField>
                        </FormCol>
                      </FormRow>
                    )}

                  {values.year?.label &&
                    activeYear.label !== NO_ITEM_MAP['year'].name && (
                      <FormRow>
                        <FormCol>
                          <CabinetFormField
                            label={'Тип коробки передач'}
                            error={
                              errors.gearbox?.label && touched.gearbox?.label
                                ? errors.gearbox?.label
                                : undefined
                            }
                          >
                            <CabinetSelect
                              value={activeGearbox}
                              options={gearboxes}
                              onChange={setActiveGearbox}
                              disabled={
                                activeGearbox.value ===
                                  SELECT_VALUE_ALL.value ||
                                gearboxes.length === 0
                              }
                            />
                          </CabinetFormField>
                        </FormCol>
                      </FormRow>
                    )}

                  {values.gearbox?.label &&
                    activeGearbox.label !== NO_ITEM_MAP['gearbox'].name && (
                      <FormRow>
                        <FormCol>
                          <CabinetFormField
                            label={'Привод'}
                            error={
                              errors.wheel?.label && touched.wheel?.label
                                ? errors.wheel?.label
                                : undefined
                            }
                          >
                            <CabinetSelect
                              value={activeWheel}
                              options={drives}
                              onChange={setActiveWheel}
                              disabled={
                                activeWheel.value === SELECT_VALUE_ALL.value ||
                                drives.length === 0
                              }
                            />
                          </CabinetFormField>
                        </FormCol>
                      </FormRow>
                    )}
                </>
              )}

              {values.carBrand !== 0 && (
                <React.Fragment>
                  <FormRow>
                    <FormCol>
                      <CabinetFormField
                        label={'Гос. номер(необязательно)'}
                        error={
                          errors.carPlate && touched.carPlate
                            ? errors.carPlate
                            : undefined
                        }
                      >
                        <CabinetTextInput
                          id='carPlate'
                          type='text'
                          name='carPlate'
                          value={values.carPlate}
                          onChange={handleChange}
                        />
                      </CabinetFormField>
                    </FormCol>
                  </FormRow>
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          <FormAction mobileActionReverse>
            <FormActionCol customWidth={50}>
              <CabinetButton variant={'contained'} onClick={onPreviousStep}>
                Назад
              </CabinetButton>
            </FormActionCol>
            {(!isManualInput || (isManualInput && values.carBrand !== 0)) && (
              <FormActionCol customWidth={50}>
                <CabinetButton type='submit' variant={'contained'}>
                  Далее
                </CabinetButton>
              </FormActionCol>
            )}
          </FormAction>
        </CabinetFormTemplate>
      </FormInnerWrapper>
    </Component>
  )
}

export default ServiceChooseCarForm

const Component = styled.form`
  position: relative;
`

const Button = styled.button`
  position: absolute;
  right: 0;
  top: 4px;
  text-decoration: underline;
  color: ${props => props.theme.blue};

  ${media.tablet(css`
    position: sticky;
    padding-left: 16px;
  `)}
`
