import { StepsProps } from './components/Steps/types'

export const stepsData: StepsProps = {
  title: 'Заполните заявку',
  steps: [
    'Выберите вид работ',
    'Выберите автомобиль',
    'Выберите услугу',
    'Выберите автоцентр',
    'Итого',
  ],
}
