import React from 'react'
import DatePicker from 'react-datepicker'

import { Field } from './CabinetDataInput.style'
import { ICabinetDataInput } from './CabinetDataInput.types'

import { ReactComponent as DateIcon } from '@/assets/svg/date-icon-cabinet.svg'
import CabinetTextInput from '@/components/PersonalCabinetForms/components/CabinetTextInput'
import useMedia from '@/hooks/useMedia'

function CabinetDataInput({
  inputName,
  data,
  setData,
  minDate,
  maxDate,
  excludeDates,
}: ICabinetDataInput) {
  const isMobile = useMedia('(max-width: 1259px)')

  return (
    <Field>
      <DatePicker
        id={inputName}
        name={inputName}
        locale='ru'
        dateFormat={['dd.MM.yyyy']}
        popperClassName='some-custom-class'
        popperPlacement='top-start'
        showYearDropdown
        scrollableYearDropdown
        excludeDates={excludeDates}
        minDate={minDate}
        maxDate={maxDate}
        yearDropdownItemNumber={80}
        selected={data}
        fixedHeight
        withPortal={!isMobile}
        closeOnScroll={!isMobile}
        onChangeRaw={e => {
          e.preventDefault()
        }}
        customInput={<CabinetTextInput name={inputName} />}
        onChange={date => {
          setData(date as Date | null)
        }}
      />
      <DateIcon />
    </Field>
  )
}

export default CabinetDataInput
