import { ServiceModelsResponseType } from '@/services/service/typing'

export const OFFLINE_REQUEST = 'offlineRequest'

const NO_MODEL_IN_LIST: ServiceModelsResponseType = {
  id: OFFLINE_REQUEST,
  name: 'Моей модели нет в списке',
}

const NO_ENGINE_IN_LIST: ServiceModelsResponseType = {
  id: OFFLINE_REQUEST,
  name: 'Моего двигателя нет в списке',
}

const NO_YEAR_IN_LIST: ServiceModelsResponseType = {
  id: OFFLINE_REQUEST,
  name: 'Моего года выпуска нет в списке',
}

const NO_GEARBOX_IN_LIST: ServiceModelsResponseType = {
  id: OFFLINE_REQUEST,
  name: 'Моей КПП нет в списке',
}

const NO_WHEEL_IN_LIST = {
  id: OFFLINE_REQUEST,
  name: 'Моего привода нет в списке',
}

export const SELECT_VALUE_ALL = {
  value: 'all',
  label: 'Все',
}

export type NoItemMapKeyType = 'model' | 'engine' | 'year' | 'gearbox' | 'wheel'

export const NO_ITEM_MAP: Record<NoItemMapKeyType, ServiceModelsResponseType> =
  {
    model: NO_MODEL_IN_LIST,
    engine: NO_ENGINE_IN_LIST,
    year: NO_YEAR_IN_LIST,
    gearbox: NO_GEARBOX_IN_LIST,
    wheel: NO_WHEEL_IN_LIST,
  }
