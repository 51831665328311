import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import styled from '@emotion/styled'

import { FormInnerWrapper } from '../common.styles'

import { ServiceChooseServiceFields, ServiceChooseServiceProps } from './types'
import {
  getOptionServicesSelect,
  initialServiceChooseServiceValues,
  validationServiceChooseServiceFormSchema,
} from './ServiceChooseServiceForm.helpers'

import CabinetFormTemplate from '@/components/PersonalCabinetForms/components/CabinetFormTemplate'
import {
  FormAction,
  FormActionCol,
  FormCol,
  FormRow,
} from '@/components/PersonalCabinetForms/components/CabinetForm.style'
import CabinetFormField from '@/components/PersonalCabinetForms/components/CabinetFormField'
import CabinetSelect from '@/components/PersonalCabinetForms/components/CabinetSelect'
import CabinetButton from '@/components/PersonalCabinetForms/components/CabinetButton'
import { OptionType } from '@/typings/common'
import Spinner from '@/components/Spinner'
import { useProfile } from '@/providers/Profile'
import { CarMaintenanceServicesResponseType } from '@/services/service/typing'
import { getInitialOptionSelect } from '@/modules/PersonalData/utils'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { getCarMaintenanceServices } from '@/services/service/service-service'

function ServiceChooseServiceForm({ onNextStep }: ServiceChooseServiceProps) {
  const { service } = useProfile()
  const { chooseService } = service

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [servicesOptions, setServicesOptions] = useState<
    CarMaintenanceServicesResponseType[]
  >([])

  const [activeService, setActiveService] = useState<OptionType>(
    getInitialOptionSelect(chooseService?.service),
  )

  const { setFieldValue, handleSubmit, errors, touched } =
    useFormik<ServiceChooseServiceFields>({
      initialValues: initialServiceChooseServiceValues,
      validationSchema: validationServiceChooseServiceFormSchema,
      onSubmit: values => {
        const service = servicesOptions?.find(
          service => service.name === values.service.label,
        )

        onNextStep(
          {
            service: { value: String(service?.id), label: service?.name ?? '' },
          } || values,
        )
      },
    })

  useUpdateEffect(() => {
    setFieldValue('service', activeService)
  }, [activeService])

  useEffect(() => {
    if (chooseService) {
      setFieldValue('service', chooseService.service)
    }

    ;(async () => {
      try {
        setIsLoading(true)

        const servicesResponse = await getCarMaintenanceServices()

        setServicesOptions(servicesResponse)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])

  return (
    <Component onSubmit={handleSubmit} noValidate autoComplete='off'>
      {isLoading && <Spinner absolute={true} isCabinet={true} />}

      <FormInnerWrapper isLoading={isLoading}>
        <CabinetFormTemplate title={'Выберите вид работ'}>
          <FormRow>
            <FormCol>
              <CabinetFormField
                error={
                  errors.service?.label && touched.service?.label
                    ? errors.service.label
                    : undefined
                }
              >
                <CabinetSelect
                  value={activeService}
                  options={getOptionServicesSelect(servicesOptions)}
                  onChange={setActiveService}
                />
              </CabinetFormField>
            </FormCol>
          </FormRow>

          <FormAction mobileActionReverse>
            {activeService.value && (
              <FormActionCol customWidth={50}>
                <CabinetButton type='submit' variant={'contained'}>
                  Далее
                </CabinetButton>
              </FormActionCol>
            )}
          </FormAction>
        </CabinetFormTemplate>
      </FormInnerWrapper>
    </Component>
  )
}

export default ServiceChooseServiceForm

const Component = styled.form`
  position: relative;
`
