import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const FormInnerWrapper = styled.div<{ isLoading?: boolean }>`
  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`
