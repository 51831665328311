import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import moment from 'moment'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { FormInnerWrapper } from '../common.styles'

import {
  getExcludedDates,
  getInitialServiceValues,
  getSelectOptionCarCenter,
  getTimeSlots,
  initialServiceChooseCarCenterFormValues,
  validationServiceChooseCarCenterFormSchema,
} from './ServiceChooseCarCenterForm.helpers'
import {
  ServiceChooseCarCenterFormFields,
  ServiceChooseCarCenterFormProps,
} from './types'

import CabinetFormTemplate from '@/components/PersonalCabinetForms/components/CabinetFormTemplate'
import {
  FormAction,
  FormActionCol,
  FormCol,
  FormNote,
  FormRow,
} from '@/components/PersonalCabinetForms/components/CabinetForm.style'
import CabinetFormField from '@/components/PersonalCabinetForms/components/CabinetFormField'
import CabinetSelect from '@/components/PersonalCabinetForms/components/CabinetSelect'
import CabinetButton from '@/components/PersonalCabinetForms/components/CabinetButton'
import { Nullable, OptionType } from '@/typings/common'
import { media } from '@/utils/mixin'
import CabinetTextInput from '@/components/PersonalCabinetForms/components/CabinetTextInput'
import TimeIcon from '@/assets/svg/time.svg'
import TimePicker from '@/components/Picker/TimePicker/TimePicker'
import Spinner from '@/components/Spinner'
import { getTomorrowDate } from '@/utils/date'
import { useProfile } from '@/providers/Profile'
import { DefaultOption } from '@/modules/PersonalData/utils'
import {
  getServiceDatesAndTime,
  getServiceOnlineBrands,
} from '@/services/service/service-service'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import CabinetDataInput from '@/components/PersonalCabinetForms/components/CabinetDataInput/CabinetDataInput'

function ServiceChooseCarCenterForm({
  onNextStep,
  onPreviousStep,
}: ServiceChooseCarCenterFormProps) {
  const { service, setServiceChooseCarDataAction } = useProfile()

  const {
    chooseProgramTO,
    chooseCarCenterData,
    chooseCarData,
    chooseService,
    offlineBrands,
  } = service

  const [recordingDate, setRecordingDate] = useState<Nullable<Date>>(
    (chooseCarCenterData?.date && new Date(chooseCarCenterData?.date)) || null,
  )

  const [isShowTimePicker, setIsShowTimePicker] = useState(false)
  const [serviceTime, setServiceTime] = useState<Nullable<string[]>>(null)

  const [isLoading, setIsLoading] = useState(false)

  const [excludedDates, setExcludedDates] = useState<Date[]>([])

  const [brands, setBrands] = useState<OptionType[]>([DefaultOption])
  const [activeBrand, setActiveBrand] = useState<OptionType>(
    getInitialServiceValues(chooseCarCenterData),
  )

  const tomorrowDate = moment(getTomorrowDate()).format('YYYY-MM-DD')
  const nextMonth = moment(tomorrowDate).add(30, 'days').format('YYYY-MM-DD')

  const {
    values,
    errors,
    setFieldValue,
    setValues,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik<ServiceChooseCarCenterFormFields>({
    initialValues: initialServiceChooseCarCenterFormValues,
    validationSchema: validationServiceChooseCarCenterFormSchema(
      chooseCarData?.availableOnlineOrder,
    ),
    onSubmit: values => {
      onNextStep(values)
    },
  })

  useEffect(() => {
    if (chooseCarCenterData) {
      setValues(chooseCarCenterData)
    }

    ;(async () => {
      try {
        setIsLoading(true)

        const dealersResponse = await getServiceOnlineBrands({
          brand: chooseCarData?.carBrand ?? 0,
          product: Number(chooseService?.service.value) ?? 0,
        })

        dealersResponse.length === 0
          ? setBrands(getSelectOptionCarCenter(offlineBrands))
          : setBrands(getSelectOptionCarCenter(dealersResponse))

        if (dealersResponse.length === 1 && !activeBrand.value) {
          const onlyOneBrand = {
            value: dealersResponse[0].id,
            label: `${dealersResponse[0].name} (${dealersResponse[0].address})`,
          }

          setActiveBrand(onlyOneBrand)
          setFieldValue('dealer', onlyOneBrand)
        }

        if (activeBrand.value && chooseCarData?.availableOnlineOrder) {
          const responseDatesAndTime = await getServiceDatesAndTime({
            serviceCenter: Number(activeBrand.value),
            product: Number(chooseService?.service.value) ?? 0,
            dateFrom: tomorrowDate,
            dateTo: nextMonth,
            minutes: chooseProgramTO?.totalMinutes ?? 0,
          })

          setServiceTime(getTimeSlots(recordingDate, responseDatesAndTime))
          setExcludedDates(getExcludedDates(responseDatesAndTime))
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])
  useUpdateEffect(() => {
    if (!chooseCarData?.availableOnlineOrder) {
      return
    }

    ;(async () => {
      try {
        setIsLoading(true)

        const responseDatesAndTime = await getServiceDatesAndTime({
          serviceCenter: Number(activeBrand.value),
          product: Number(chooseService?.service.value) ?? 0,
          dateFrom: tomorrowDate,
          dateTo: nextMonth,
          minutes: chooseProgramTO?.totalMinutes ?? 0,
        })

        setServiceTime(getTimeSlots(recordingDate, responseDatesAndTime))
        setExcludedDates(getExcludedDates(responseDatesAndTime))
      } catch (error) {
        setServiceChooseCarDataAction({
          ...chooseCarData,
          availableOnlineOrder: false,
        })
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [activeBrand, recordingDate])
  useUpdateEffect(() => {
    setFieldValue('dealer', activeBrand)
    setRecordingDate(null)
    setFieldValue('date', '')

    setServiceTime(null)
    setFieldValue('time', '')
  }, [activeBrand])
  useUpdateEffect(() => {
    setFieldValue('date', recordingDate)
  }, [recordingDate])

  const handleChangeDealerSelect = (option: OptionType) => {
    setActiveBrand(option)
  }

  return (
    <Component onSubmit={handleSubmit} noValidate autoComplete='off'>
      {isLoading && <Spinner absolute={true} isCabinet={true} />}

      <FormInnerWrapper isLoading={isLoading}>
        <CabinetFormTemplate title={'Выбор автоцентра'}>
          <FormRow>
            <FormCol>
              <CabinetFormField
                label={'Автоцентр'}
                error={
                  errors.dealer && touched.dealer
                    ? errors.dealer.label
                    : undefined
                }
              >
                <CabinetSelect
                  value={activeBrand}
                  options={brands}
                  onChange={handleChangeDealerSelect}
                />
              </CabinetFormField>
            </FormCol>
          </FormRow>

          <FormRowWrapper>
            <FormRow>
              <FormCol>
                <CabinetFormField
                  label={'Дата'}
                  error={errors.date && touched.date ? errors.date : undefined}
                >
                  <CabinetDataInput
                    inputName='date'
                    data={recordingDate}
                    setData={(value: Nullable<Date>) => {
                      setRecordingDate(value)
                      setFieldValue('date', moment(value).format('YYYY-MM-DD'))
                      setServiceTime(null)
                      setFieldValue('time', '')
                    }}
                    minDate={getTomorrowDate()}
                    maxDate={new Date(nextMonth)}
                    excludeDates={excludedDates}
                  />

                  {!chooseCarData?.availableOnlineOrder && (
                    <FormNote>
                      <span>*</span> Дата записи является предварительной
                    </FormNote>
                  )}
                </CabinetFormField>
              </FormCol>

              <FormCol>
                {chooseCarData?.availableOnlineOrder &&
                  ((serviceTime && serviceTime.length > 0) || values.time) && (
                    <CabinetFormField
                      label={'Время'}
                      error={
                        errors.time && touched.time ? errors.time : undefined
                      }
                    >
                      <CabinetTextInput
                        readOnly
                        id='time'
                        name='time'
                        value={values.time}
                        onChange={handleChange}
                        badge={<TimeIcon />}
                        onClick={() => setIsShowTimePicker(!isShowTimePicker)}
                      />

                      {isShowTimePicker && (
                        <StyledTimePicker
                          value={values.time ?? ''}
                          times={serviceTime}
                          onClick={value => {
                            setFieldValue('time', value)
                            setIsShowTimePicker(false)
                          }}
                          onClose={() => setIsShowTimePicker(false)}
                        />
                      )}
                    </CabinetFormField>
                  )}
              </FormCol>
            </FormRow>
          </FormRowWrapper>

          <FormAction mobileActionReverse>
            <FormActionCol>
              <CabinetButton variant={'contained'} onClick={onPreviousStep}>
                Назад
              </CabinetButton>
            </FormActionCol>
            <FormActionCol>
              <CabinetButton type='submit' variant={'contained'}>
                Далее
              </CabinetButton>
            </FormActionCol>
          </FormAction>
        </CabinetFormTemplate>
      </FormInnerWrapper>
    </Component>
  )
}

export default ServiceChooseCarCenterForm

const Component = styled.form`
  position: relative;
`

const FormRowWrapper = styled.div`
  padding-top: 17px;
`

const StyledTimePicker = styled(TimePicker)`
  width: calc(100% - 28px);
  margin: 0 16px;

  ${media.mobile(css`
    width: 100%;
    margin: 0;
    top: -40px;
  `)}
`
