import * as Yup from 'yup'

import { ServiceChooseServiceFields } from './types'

import { OptionType } from '@/typings/common'
import { DefaultOption } from '@/modules/PersonalData/utils'
import { CarMaintenanceServicesResponseType } from '@/services/service/typing'

export const getOptionServicesSelect = (
  services?: CarMaintenanceServicesResponseType[],
): OptionType[] => {
  if (!services) {
    return [DefaultOption]
  }

  return services.map(service => ({
    value: service.name,
    label: service.name,
  }))
}

export const initialServiceChooseServiceValues: ServiceChooseServiceFields = {
  service: { value: '', label: '' },
}

export const validationServiceChooseServiceFormSchema: Yup.Schema<ServiceChooseServiceFields> =
  Yup.object({
    service: Yup.object({
      label: Yup.string().required('Выберите услугу'),
      value: Yup.string().required('Выберите услугу'),
    }),
    availableOnlineOrder: Yup.boolean(),
  })
