import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
// @ts-ignore
import { IMaskInput } from 'react-imask'
import styled from '@emotion/styled'

import { FormInnerWrapper } from '../common.styles'

import {
  initialServiceSummaryForm,
  validationServiceSummaryFormSchema,
} from './ServiceSummaryForm.helpers'
import { ServiceSummaryFormProps } from './types'
import Summary from './components/Summary'
import { useGetSummary } from './hooks'

import {
  FormAction,
  FormActionCol,
  FormCol,
  FormNote,
  FormRow,
} from '@/components/PersonalCabinetForms/components/CabinetForm.style'
import CabinetFormField from '@/components/PersonalCabinetForms/components/CabinetFormField'
import CabinetTextArea from '@/components/PersonalCabinetForms/components/CabinetTextArea'
import CabinetButton from '@/components/PersonalCabinetForms/components/CabinetButton'
import CabinetCheckbox from '@/components/PersonalCabinetForms/components/CabinetCheckbox'
import Spinner from '@/components/Spinner'
import CabinetTextInput from '@/components/PersonalCabinetForms/components/CabinetTextInput/CabinetTextInput'
import { InputStyles } from '@/components/PersonalCabinetForms/components/CabinetTextInput/CabinetTextInput.style'
import CabinetFormTemplate from '@/components/PersonalCabinetForms/components/CabinetFormTemplate'
import { useProfile } from '@/providers/Profile'

function ServiceSummaryForm({
  onPreviousStep,
  onNextStep,
}: ServiceSummaryFormProps) {
  const { service, profile } = useProfile()
  const { chooseCarData } = service

  const [otherSummary] = useGetSummary()
  const [checkedCallBack, setCheckedCallBack] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    errors,
    touched,
    values,
    handleChange,
    setValues,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: initialServiceSummaryForm,
    validationSchema: validationServiceSummaryFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      setIsLoading(true)

      try {
        setIsLoading(true)
        await onNextStep({ ...values, callBack: checkedCallBack })
      } catch (error: any) {
        console.log(error)
      } finally {
        setIsLoading(false)
        setSubmitting(false)
      }
    },
  })
  useEffect(() => {
    setValues({
      surname: profile.data?.lastName ?? '',
      name: profile.data?.name ?? '',
      phone: profile.data?.phones[0] ?? '',
    })
  }, [profile.data, setValues])

  return (
    <Component>
      <Form onSubmit={handleSubmit} noValidate autoComplete='off'>
        {isLoading && <Spinner absolute={true} isCabinet={true} />}

        <FormInnerWrapper isLoading={isLoading}>
          <CabinetFormTemplate title='Данные записи'>
            <TotalWrapper>
              <Summary data={otherSummary} />
            </TotalWrapper>

            <FormRow>
              <FormCol>
                <CabinetFormField
                  label={'Фамилия'}
                  error={
                    errors.surname && touched.surname
                      ? errors.surname
                      : undefined
                  }
                >
                  <CabinetTextInput
                    id='surname'
                    type='text'
                    name='surname'
                    value={values.surname}
                    onChange={handleChange}
                  />
                </CabinetFormField>
              </FormCol>
            </FormRow>

            <FormRow>
              <FormCol>
                <CabinetFormField
                  label={'Имя'}
                  error={errors.name && touched.name ? errors.name : undefined}
                >
                  <CabinetTextInput
                    id='name'
                    type='text'
                    name='name'
                    value={values.name}
                    onChange={handleChange}
                  />
                </CabinetFormField>
              </FormCol>
            </FormRow>

            <FormRow>
              <FormCol>
                <CabinetFormField
                  label={'Телефон'}
                  error={
                    errors.phone && touched.phone ? errors.phone : undefined
                  }
                >
                  <CabinetTextInputPhone
                    type='tel'
                    id='phone'
                    name='phone'
                    value={values.phone}
                    onAccept={(value: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('phone', value)
                    }
                    onChange={handleChange}
                    mask={process.env.REACT_APP_PHONE_MASK}
                  />
                </CabinetFormField>
              </FormCol>
            </FormRow>

            <FormRow>
              <FormCol>
                <CabinetFormField
                  label={'Комментарий'}
                  error={
                    errors.comment && touched.comment
                      ? errors.comment
                      : undefined
                  }
                >
                  <CabinetTextArea
                    id='comment'
                    name='comment'
                    value={values.comment}
                    onChange={handleChange}
                  />
                  <FormNote>
                    При необходимости, укажите примечания и пояснения
                  </FormNote>
                </CabinetFormField>
              </FormCol>
            </FormRow>

            {chooseCarData?.availableOnlineOrder && (
              <FormCol>
                <CabinetCheckbox
                  name={'callBack'}
                  isChecked={checkedCallBack}
                  text='Требуется обратный звонок'
                  setChecked={() => {
                    setCheckedCallBack(!checkedCallBack)
                  }}
                />
              </FormCol>
            )}

            <FormAction mobileActionReverse>
              <FormActionCol customWidth={50}>
                <CabinetButton variant={'contained'} onClick={onPreviousStep}>
                  Назад
                </CabinetButton>
              </FormActionCol>
              <FormActionCol customWidth={50}>
                <CabinetButton type='submit' variant={'contained'}>
                  Завершить
                </CabinetButton>
              </FormActionCol>
            </FormAction>
          </CabinetFormTemplate>
        </FormInnerWrapper>
      </Form>
    </Component>
  )
}

export default ServiceSummaryForm

const Component = styled.div``

const TotalWrapper = styled.div`
  padding: 0 16px;
`

const CabinetTextInputPhone = styled(IMaskInput)`
  ${InputStyles}
`

const Form = styled.form``
