import React from 'react'
import moment from 'moment/moment'
import styled from '@emotion/styled'

import SuccessForm from '@/components/Forms/SuccessForm'
import { useProfile } from '@/providers/Profile'

function Success() {
  const { brands, service } = useProfile()
  const brandsData = brands.data
  const carCenterData = service.chooseCarCenterData

  if (!carCenterData || !brands) {
    return null
  }

  const isRegularLead = !carCenterData.time

  const dealer =
    brandsData
      .map(brand => brand.dealers)
      .flat(1)
      .find(dealer => dealer.id === carCenterData.dealer.value)?.name ?? ''

  const message = `Ваша запись успешно сформирована на <span>${moment(
    carCenterData?.date,
  ).format('DD.MM.YYYY')}</span>, <span>${
    carCenterData.time
  }</span>, <span>${dealer}</span>. Ждём!`

  return isRegularLead ? (
    <SuccessForm />
  ) : (
    <Component dangerouslySetInnerHTML={{ __html: message }} />
  )
}

export default Success

const Component = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;

  span {
    color: ${props => props.theme.main};
  }
`
