import * as Yup from 'yup'

import { ServiceSummaryFormFields } from './types'

import { phoneRegexp } from '@/utils/textInput'

export const initialServiceSummaryForm: ServiceSummaryFormFields = {
  comment: '',
  callBack: false,
  surname: '',
  name: '',
  phone: '',
}

export const validationServiceSummaryFormSchema: Yup.Schema<ServiceSummaryFormFields> =
  Yup.object().shape({
    comment: Yup.string(),
    callBack: Yup.boolean(),
    surname: Yup.string(),
    name: Yup.string().required('Укажите имя'),
    phone: Yup.string()
      .required('Укажите номер телефона')
      .matches(phoneRegexp, 'Телефон введен неверно'),
  })
