export type Nullable<T> = T | null

export type Nullish<T> = T | null | undefined

export type ConstantMap<C extends string> = Readonly<Record<C, C>>

export type FetchStatus = 'IDLE' | 'LOADING' | 'SUCCESS' | 'FAILURE'

export const FETCH_STATUSES: ConstantMap<FetchStatus> = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
}

export interface OptionType<T = string> {
  value: T
  label: string
}

export type MenuItemType = {
  readonly id: number
  readonly label: string
  readonly link: Nullable<string>
  readonly isNewTab: boolean
  readonly children: Array<MenuItemType>
}

export type ThumbnailType = {
  readonly url: Nullable<string>
  readonly url_2x?: Nullable<string>
  readonly url_webp?: Nullable<string>
  readonly url_webp_2x?: Nullable<string>
}

export type StringField = Nullable<string>

export type WebsiteSourceType =
  | 'ATLANTM'
  | 'B2B'
  | 'HISUN'
  | 'JAC'
  | 'SOLLERS'
  | 'JETOUR'
  | 'JETOUR_AUTO'
  | 'JETOUR_GOMEL'
  | 'JETOUR_GRODNO'
  | 'JETOUR_VITEBSK'
  | 'ZEEKR'

export interface UtmType {
  utmSource: string
  utmMedium: string
  utmCampaign: string
  utmTerm: string
  utmContent: string
}

export const websiteSource = 'LKK'

export interface IData<T> {
  status: FetchStatus
  data: T
}

export type CarsType = 'new' | 'amp'

export interface ITestDriveFormUserValuesData {
  date: Nullable<Date>
  carId: string
  dealerId: Nullable<number>
  phone: string
  comment: string
  name: string
  email: string
  outsideTestDrive: boolean
  time: string
}
