import React from 'react'
import { useSelect } from 'downshift'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-down-cabinet.svg'
import { media } from '@/utils/mixin'
import { OptionType } from '@/typings/common'
import { ScrollStyles } from '@/components/styles'

export interface CabinetSelectProps<T = string> {
  value: OptionType<T>
  onChange?: (newSelectedValue: OptionType<T>) => void
  options: OptionType<T>[]
  className?: string
  disabled?: boolean
}

function CabinetSelect<V>({
  value,
  options,
  onChange,
  className,
  disabled,
}: CabinetSelectProps<V>) {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    selectedItem: value,
    items: options,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem?.value === value.value) {
        return
      }

      onChange?.(selectedItem ?? options[0])
    },
  })

  return (
    <Component className={className}>
      <Display
        type='button'
        {...getToggleButtonProps()}
        isOpen={isOpen}
        disabled={disabled}
      >
        <SelectedItem>{selectedItem?.label}</SelectedItem>
        <StyledArrowIcon isOpen={isOpen} />
      </Display>
      <DropdownMenu {...getMenuProps()} isOpen={isOpen}>
        {options.length > 0 ? (
          <OptionList>
            {options.map((option, index) => (
              <OptionItem
                key={`${option.value}${index}`}
                {...getItemProps({
                  item: option,
                  index,
                  isSelected: option.value === value?.value,
                })}
              >
                {option.label}
              </OptionItem>
            ))}
          </OptionList>
        ) : (
          <NoOptions>No options</NoOptions>
        )}
      </DropdownMenu>
    </Component>
  )
}

export default CabinetSelect

const Component = styled.div`
  position: relative;
  width: 100%;
`

const Display = styled.button<{ isOpen: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 10px 15px;
  background: ${props => props.theme.white};
  border: none;
  border-radius: 0;
  box-shadow: 0 0 0 1px ${props => props.theme.gray400} inset;

  cursor: pointer;
  transition: 0.15s;

  &:hover {
    box-shadow: 0 0 0 1px ${props => props.theme.gray400} inset;
    background: rgba(0, 97, 237, 0.03);
  }

  ${({ isOpen, theme }) =>
    isOpen &&
    css`
      box-shadow: 0 0 0 1px ${theme.main} inset !important;
      background: ${theme.white} !important;
    `};

  ${({ disabled, theme }) =>
    disabled &&
    css`
      pointer-events: none;
      background: ${theme.gray200} !important;
    `};
`

const SelectedItem = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.blue100};
`

const DropdownMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px;
  padding: 30px;
  border: 1px solid ${props => props.theme.gray400};
  background: ${props => props.theme.white};
  color: ${props => props.theme.black100};
  box-shadow: 0 5px 30px 0 rgb(23 43 77 / 10%);
  cursor: default;
  word-wrap: break-word;
  word-break: break-word;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  transition:
    0.15s,
    left 0s;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  z-index: 4;

  max-height: calc(80vh - 40px);
  overflow-y: auto;

  ${ScrollStyles}

  ${media.mobile(css`
    padding: 20px;
  `)}

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    `};
`

const StyledArrowIcon = styled(ArrowIcon)<{ isOpen: boolean }>`
  transition: 0.3s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `};
`

const OptionList = styled.ul`
  margin: -8px 0;
`

const OptionItem = styled.li<{ isSelected: boolean }>`
  display: block;
  padding: 8px 30px;
  margin: 0 -30px;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.blue100};
  outline: 0;
  border: none;
  cursor: pointer;
  transition: 0.15s;

  ${media.tabletSmall(css`
    cursor: default;
  `)}

  ${media.mobile(css`
    margin: 0 -20px;
    padding: 8px 20px;
  `)}

  &:hover {
    color: ${props => props.theme.blue100};
    outline: 0;
    background: ${props => props.theme.gray200};
  }
`

const NoOptions = styled.div``
