import * as Yup from 'yup'

import { ServiceChooseProgramTOFields } from './types'

import { Nullable } from '@/typings/common'
import { ServiceProgramListResponseType } from '@/services/service/typing'

export const getTotalInfoProgram = (
  programTO: Nullable<ServiceProgramListResponseType>,
): [totalMinutes: number, totalCoast: number] => {
  if (!programTO) {
    return [0, 0]
  }

  const program = [...programTO.works, ...programTO.parts]

  const totalCoast = Number(
    program.reduce((acc, item) => acc + item.price, 0).toFixed(2),
  )

  const totalMinutes = Number(
    programTO.works.reduce((acc, item) => acc + item.minutes, 0).toFixed(2),
  )

  return [totalMinutes, totalCoast]
}

export const isHaveZeroPrice = (
  program: Nullable<ServiceProgramListResponseType>,
): boolean => {
  if (!program) return true

  const { works, parts } = program

  const isZeroWorksPrice = works.some(el => el.price === 0)
  const isZeroPartsPrice = parts.some(el => el.price === 0)

  return isZeroWorksPrice || isZeroPartsPrice
}

export const validationServiceProgramTOFormSchema: Yup.Schema<ServiceChooseProgramTOFields> =
  Yup.object().shape({
    program: Yup.object({
      label: Yup.string().required('Выберите услугу'),
      value: Yup.string().required('Выберите услугу'),
    }),
    totalCoast: Yup.number(),
    totalMinutes: Yup.number(),
    isZeroPrice: Yup.boolean(),
  })

export const initialServiceChooseProgramTOFormValues: ServiceChooseProgramTOFields =
  {
    program: { value: '', label: '' },
  }
