import moment from 'moment'

import {
  CabinetBrandsPayload,
  UserProfileModel,
  ServiceCarsPayload,
} from '@/services/users/typings'
import { Nullable, OptionType } from '@/typings/common'
import {
  IdNameType,
  ServiceCarCentersResponseType,
} from '@/services/service/typing'

export const getProfilePercentFullness = (
  profileInformation: Nullable<UserProfileModel>,
) => {
  if (!profileInformation) {
    return 0
  }

  const {
    socialGoogle,
    socialYandex,
    socialVkontakte,
    name,
    patronymic,
    lastName,
    birthdate,
    gender,
    emails,
    phones,
  } = profileInformation

  const dataArray = [
    socialGoogle,
    // socialFacebook,
    socialYandex,
    socialVkontakte,
    name,
    patronymic,
    lastName,
    birthdate,
    gender,
    emails[0],
    phones[0],
    // passport.country,
    // passport.seria,
    // passport.number,
    // passport.personalNumber,
    // passport.company,
    // passport.date,
    // livingAddress.country && livingAddress.city,
    // livingAddress.zip,
    // livingAddress.street,
    // livingAddress.house,
    // livingAddress.korpus,
    // livingAddress.apartment,
    // registrationAddress.country && registrationAddress.city,
    // registrationAddress.zip,
    // registrationAddress.street,
    // registrationAddress.house,
    // registrationAddress.korpus,
    // registrationAddress.apartment,
  ]

  const dataArrayLength = dataArray.length
  const dataArrayNoEmpty = dataArray.filter(Boolean).length

  return Math.ceil((100 / dataArrayLength) * dataArrayNoEmpty)
}

export const handleSortCabinetDataTableByDate = (
  activeValue: string,
  list: any,
) => {
  if (activeValue !== 'new') {
    return list.sort(
      (firstDate: any, secondDate: any) =>
        new Date(firstDate.date).valueOf() -
        new Date(secondDate.date).valueOf(),
    )
  } else {
    return list.sort(
      (firstDate: any, secondDate: any) =>
        new Date(secondDate.date).valueOf() -
        new Date(firstDate.date).valueOf(),
    )
  }
}

export const getHoursAndMinutes = (
  seconds: number,
): { hours: number; minutes: number } => {
  const hoursAndMinutes = moment.utc(seconds * 1000).format('H:m')
  const splitHoursAndMinutes = hoursAndMinutes.split(':')

  return {
    hours: Number(splitHoursAndMinutes[0]),
    minutes: Number(splitHoursAndMinutes[1]),
  }
}

export const getHoursAndMinutesFormattedText = (
  hours: number,
  minutes: number,
): string => {
  return `${hours ? hours + ' ч' : ''} ${minutes ? minutes + ' мин' : ''}`
}

export const DefaultOption: OptionType = { value: '', label: '' }

export const getDealerOptions = (
  brandId: number,
  brands: CabinetBrandsPayload[],
): OptionType[] => {
  if (brandId) {
    const foundDealers = brands.find(brand => brand.id === brandId)?.dealers

    if (!foundDealers || foundDealers.length === 0) {
      return getFullDealerOptions(brands)
    }

    return foundDealers.map(({ name }) => {
      return { value: name, label: name }
    })
  } else {
    return getFullDealerOptions(brands)
  }
}

export const getDealerId = (
  brands: CabinetBrandsPayload[],
  searchDealer: string,
): number => {
  if (!brands || brands.length === 0) {
    return 0
  }

  const foundDealer = brands
    .map(({ dealers }) => dealers.find(dealer => dealer.name === searchDealer))
    .filter(Boolean)

  return foundDealer[0]?.id ?? 0
}

export const getFullDealerOptions = (
  brands: CabinetBrandsPayload[],
): OptionType[] => {
  if (brands && brands.length !== 0) {
    return [
      ...brands
        .map(({ dealers }) => {
          return dealers.map(({ name }) => {
            return { value: name, label: name }
          })
        })
        .flat(1),
    ]
  } else {
    return [DefaultOption]
  }
}

export const getCarById = (
  id: number,
  cars: ServiceCarsPayload[],
): ServiceCarsPayload | undefined => {
  return cars.find(car => car.id === id)
}

export const getBrandById = (
  id: number,
  brands: CabinetBrandsPayload[],
): CabinetBrandsPayload | undefined => {
  return brands.find(brand => brand.id === id)
}

export const getInitialOptionSelect = (initValue?: OptionType): OptionType => {
  if (initValue) {
    return { value: initValue.value, label: initValue.label }
  }

  return DefaultOption
}

export const getSelectOption = (
  options: IdNameType[] | ServiceCarCentersResponseType[],
): OptionType[] => {
  if (!options) return [DefaultOption]

  return options.map(option => ({ value: option.id, label: option.name }))
}
