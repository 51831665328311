import styled from '@emotion/styled/macro'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'

export const FormRow = styled.div`
  display: flex;
  padding: 15px 0;

  ${media.mobile(css`
    display: block;
    padding: 24px 16px 0;

    &:first-child {
      padding-top: 15px;
    }
  `)}
`

export const FormCol = styled.div<{ isSmall?: boolean; customWidth?: number }>`
  position: relative;
  flex: 1 1 50%;
  padding: 0 16px;

  ${props =>
    props.isSmall &&
    css`
      flex: 0 0 30%;
    `}

  ${media.mobile(css`
    padding: 24px 0 0;

    &:first-child {
      padding-top: 0;
    }
  `)}

  ${({ customWidth }) =>
    customWidth &&
    css`
      flex: 1 1 ${customWidth}%;
      max-width: ${customWidth}%;

      ${media.mobile(css`
        flex: 1 1 auto;
        max-width: none;
      `)}
    `}
`

export const FormAction = styled.div<{ mobileActionReverse?: boolean }>`
  display: flex;
  margin: 10px 0 -5px;

  ${media.mobile(css`
    margin-top: 25px;
    display: block;
  `)}

  ${({ mobileActionReverse }) =>
    mobileActionReverse &&
    css`
      ${media.mobile(css`
        display: flex;
        flex-direction: column-reverse;
      `)}
    `}
`

export const FormActionCol = styled.div<{ customWidth?: number }>`
  flex: 1 1 auto;
  padding: 5px 16px;

  ${({ customWidth }) =>
    customWidth &&
    css`
      flex: 1 1 ${customWidth}%;
      max-width: ${customWidth}%;

      ${media.mobile(css`
        flex: 1 1 auto;
        max-width: none;
      `)}
    `}
`

export const FormDivider = styled.div`
  margin: 32px 0;
  height: 1px;
  background: ${props => props.theme.gray200};
`

export const FormOverlay = styled.div<{ isLoading: boolean }>`
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: ${props => props.theme.white_1};
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    `}
`

export const FormNote = styled.p`
  font-size: 14px;
  line-height: 20px;
  padding: 0 16px;
  margin-top: 10px;
  color: ${props => props.theme.blue100};

  ${media.mobile(css`
    margin-top: 5px;
  `)}

  span {
    color: ${props => props.theme.red100};
  }
`
