import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'

import { getDealerLinkById } from './helpers'

import { media } from '@/utils/mixin'
import { CarsItemType } from '@/services/users/typings'
import NoImageCar from '@/assets/images/defaultStockCar.png'
import CabinetButton from '@/components/PersonalCabinetForms/components/CabinetButton'
import CabinetSwitch from '@/components/PersonalCabinetForms/components/CabinetSwitch'
import { toggleShowCar } from '@/services/users/users-service'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import NumberPlate from '@/components/NumberPlate'
import { useDealers } from '@/providers/Dealers'
import { useProfile } from '@/providers/Profile'
import RepairPartsFormButton from '@/modules/Cars/components/CarsList/components/СarsCard/components/RepairPartsFormButton'
import CarValueModalButton from '@/modules/Cars/components/CarsList/components/СarsCard/components/CarValueModalButton'
import { useRedirectServicePage } from '@/hooks/useRedirectServicePage'

interface Props {
  isView?: boolean
  car: CarsItemType
  index: number
  hiddenList?: number[]
  setHiddenList?: (value: number[]) => void
}

function CarsCard({ isView, car, index, hiddenList, setHiddenList }: Props) {
  const { state } = useDealers()
  const { brands, getServiceCarsAction, getProfilePreviewAction } = useProfile()
  const redirect = useRedirectServicePage()

  const {
    id,
    image,
    vin,
    brandName,
    modelName,
    number,
    mileage,
    isOwner,
    hidden,
    purchaseInfo,
  } = car
  const [isHidden, setHidden] = useState<boolean>(setDefaultHiddenValue())

  function setDefaultHiddenValue() {
    if (hiddenList) {
      return hiddenList.findIndex(hiddenCard => hiddenCard === index) !== -1
    } else {
      return hidden
    }
  }

  async function handleArchived() {
    await toggleShowCar(id ?? 0)
      .then(() => {
        getServiceCarsAction()
        getProfilePreviewAction()
      })
      .catch((error: any) => {
        console.log(error, 'error')
      })
  }

  useUpdateEffect(() => {
    if (setHiddenList && hiddenList) {
      if (isHidden) {
        setHiddenList([...hiddenList, index])
      } else {
        let cloneHiddenList = cloneDeep(hiddenList)
        cloneHiddenList = cloneHiddenList.filter(item => item !== index)

        setHiddenList(cloneHiddenList)
      }
    }

    handleArchived().catch(error => {
      console.log(error)
      toast.error('Error!')
    })
  }, [isHidden])

  return (
    <Card>
      <Inner>
        <Preview>
          <Image>
            <img
              loading='lazy'
              src={image && image.url ? image.url : NoImageCar}
              alt={brandName ?? 'car name'}
            />
          </Image>
          {isOwner && number && <NumberPlate value={number} isLarge={true} />}
          <Toggle>
            <CabinetSwitch
              name={`autoHidden-${index}`}
              text={'Скрыть авто'}
              isChecked={isHidden}
              setChecked={(value: boolean) => setHidden(value)}
            />
          </Toggle>
        </Preview>

        <Description>
          <Title>
            {brandName} {modelName}
          </Title>
          <Params>
            {mileage && <Mileage>Пробег · {mileage} км</Mileage>}
            <ParamsText>VIN {vin}</ParamsText>
            {purchaseInfo && (
              <>
                <ParamsText>
                  Дата покупки ・{' '}
                  {moment(purchaseInfo.date).format('DD.MM.YYYY')}
                </ParamsText>
                <ParamsText>
                  Автоцентр ・
                  <ParamsLink
                    to={getDealerLinkById(state.value, purchaseInfo.dealerId)}
                  >
                    {purchaseInfo.dealerName}
                  </ParamsLink>
                </ParamsText>
              </>
            )}
          </Params>
          {!isView && (
            <ViewPageLink to={`/cars/${id}`}>Подробная информация</ViewPageLink>
          )}
        </Description>

        <Action>
          {!Boolean(isHidden) && (
            <>
              <ActionCell>
                <CabinetButton variant={'contained'} onClick={redirect}>
                  Запись на сервис
                </CabinetButton>
              </ActionCell>
              <ActionCell>
                <RepairPartsFormButton car={car} brands={brands} />
              </ActionCell>
              <ActionCell>
                <CarValueModalButton car={car} brands={brands} />
              </ActionCell>
            </>
          )}
        </Action>
      </Inner>
    </Card>
  )
}

const Card = styled.div`
  position: relative;
`

const Inner = styled.div`
  display: flex;
  background-color: ${props => props.theme.white};
  padding: 30px 40px;

  ${media.tabletSmall(css`
    padding: 30px 20px;
  `)}

  ${media.mobile(css`
    display: block;
  `)}
`

const Preview = styled.div`
  flex: 0 0 250px;
  margin-right: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.tabletSmall(css`
    flex: 0 0 180px;
  `)}

  ${media.mobile(css`
    width: 100%;
    margin: 0 0 25px;
  `)}
`

const Description = styled.div`
  flex: 1 1 auto;
`

const Action = styled.div`
  flex: 0 0 250px;
  max-width: 250px;
  margin-left: 30px;

  ${media.mobile(css`
    width: 100%;
    max-width: 100%;
    margin: 20px 0 0;
  `)}
`

const ActionCell = styled.div`
  margin-bottom: 10px;
  position: relative;
  z-index: 5;

  &:last-child {
    margin-bottom: 0;
  }
`

const Image = styled.div`
  margin-bottom: 10px;
  height: 140px;

  display: flex;
  justify-content: center;
  align-items: center;

  div,
  picture,
  img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`

const Title = styled.h3`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.theme.black100};
  margin: 0;

  ${media.mobile(css`
    font-size: 20px;
    line-height: 28px;
  `)}
`

const Params = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;

  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`

const Toggle = styled.div`
  margin-top: 10px;
  position: relative;
  z-index: 15;
`

const Mileage = styled.span`
  font-weight: 700;
  color: ${props => props.theme.black100};
`

const ParamsText = styled.span`
  color: ${props => props.theme.gray500};
`

const ParamsLink = styled(Link)`
  color: ${props => props.theme.main};
  text-decoration: underline !important;
  text-decoration-color: transparent !important;
  transition: 0.15s;

  &:hover {
    text-decoration-color: ${props => props.theme.main} !important;
  }
`

const ViewPageLink = styled(Link)`
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.main};
  transition:
    color 0.15s,
    border 0.15s,
    opacity 0.15s;
  text-decoration: underline !important;
  text-decoration-color: transparent !important;

  &:hover {
    text-decoration-color: ${props => props.theme.main} !important;
  }
`

export default CarsCard
