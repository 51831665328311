import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import styled from '@emotion/styled'

import { FormInnerWrapper } from '../common.styles'

import {
  ServiceChooseProgramTOFields,
  ServiceChooseProgramTOFormProps,
} from './types'
import ProgramTable from './components/ProgramTable'
import Program from './components/Program'
import {
  getTotalInfoProgram,
  initialServiceChooseProgramTOFormValues,
  isHaveZeroPrice,
  validationServiceProgramTOFormSchema,
} from './ServiceChooseProgramTOForm.helpers'

import CabinetFormTemplate from '@/components/PersonalCabinetForms/components/CabinetFormTemplate'
import {
  FormAction,
  FormActionCol,
  FormCol,
  FormNote,
  FormRow,
} from '@/components/PersonalCabinetForms/components/CabinetForm.style'
import CabinetFormField from '@/components/PersonalCabinetForms/components/CabinetFormField'
import CabinetButton from '@/components/PersonalCabinetForms/components/CabinetButton'
import CabinetSelect from '@/components/PersonalCabinetForms/components/CabinetSelect'
import { Nullable, OptionType } from '@/typings/common'
import Spinner from '@/components/Spinner'
import { useProfile } from '@/providers/Profile'
import {
  DefaultOption,
  getInitialOptionSelect,
  getSelectOption,
} from '@/modules/PersonalData/utils'
import { ServiceProgramListResponseType } from '@/services/service/typing'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import {
  getProgramList,
  getServiceInterval,
} from '@/services/service/service-service'

function ServiceChooseProgramTOForm({
  onPreviousStep,
  onNextStep,
}: ServiceChooseProgramTOFormProps) {
  const { service, setServiceChooseCarDataAction } = useProfile()
  const { chooseCarData, cars, chooseProgramTO } = service

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOfflineRequest, setIsOfflineRequest] = useState<boolean>(false)
  const [programs, setPrograms] = useState<OptionType[]>([DefaultOption])
  const [programList, setProgramList] =
    useState<Nullable<ServiceProgramListResponseType>>(null)

  const [activeProgram, setActiveProgram] = useState<OptionType>(
    getInitialOptionSelect(chooseProgramTO?.program),
  )

  const [totalMinutes, totalCoast] = getTotalInfoProgram(programList)

  const { handleSubmit, setFieldValue, errors, touched } =
    useFormik<ServiceChooseProgramTOFields>({
      initialValues: initialServiceChooseProgramTOFormValues,
      validationSchema: validationServiceProgramTOFormSchema,
      onSubmit: values => {
        onNextStep({
          ...values,
          totalCoast,
          totalMinutes,
          isZeroPrice: isOfflineRequest,
        })
      },
    })

  const carServiceInfo = cars.find(car => car.brand === chooseCarData?.carBrand)
    ?.serviceInfo?.data
  const isMyCar = !!chooseCarData?.carId

  useEffect(() => {
    ;(async () => {
      if (chooseProgramTO) {
        setFieldValue('program', chooseProgramTO.program)
      }

      const params = {
        brand: chooseCarData?.carBrand ?? 0,
        model:
          (isMyCar ? carServiceInfo?.model : chooseCarData?.carModel.value) ??
          '',
        engine:
          (isMyCar ? carServiceInfo?.engine : chooseCarData?.engine.value) ??
          '',
        year:
          (isMyCar ? carServiceInfo?.year : chooseCarData?.year.value) ?? '',
        gearbox:
          (isMyCar ? carServiceInfo?.gearbox : chooseCarData?.gearbox.value) ??
          '',
        wheel:
          (isMyCar ? carServiceInfo?.wheel : chooseCarData?.wheel.value) ?? '',
      }

      if (
        params.brand &&
        params.model &&
        params.engine &&
        params.year &&
        params.gearbox &&
        params.wheel
      ) {
        try {
          setIsLoading(true)
          const programsResponse = await getServiceInterval({ ...params })
          setPrograms(getSelectOption(programsResponse.items))
        } catch (error) {
        } finally {
          setIsLoading(false)
        }
      } else {
        if (chooseCarData) {
          setServiceChooseCarDataAction({
            ...chooseCarData,
            availableOnlineOrder: false,
          })
        }
        onNextStep(initialServiceChooseProgramTOFormValues)
      }
    })()
  }, [])

  useUpdateEffect(() => {
    if (activeProgram.value) {
      const params = {
        brand: chooseCarData?.carBrand ?? 0,
        model:
          (isMyCar ? carServiceInfo?.model : chooseCarData?.carModel.value) ??
          '',
        engine:
          (isMyCar ? carServiceInfo?.engine : chooseCarData?.engine.value) ??
          '',
        year:
          (isMyCar ? carServiceInfo?.year : chooseCarData?.year.value) ?? '',
        gearbox:
          (isMyCar ? carServiceInfo?.gearbox : chooseCarData?.gearbox.value) ??
          '',
        wheel:
          (isMyCar ? carServiceInfo?.wheel : chooseCarData?.wheel.value) ?? '',
        interval: activeProgram.value,
      }

      ;(async () => {
        try {
          setIsLoading(true)

          const programListResponse = await getProgramList({ ...params })
          setProgramList(programListResponse)

          if (isHaveZeroPrice(programListResponse)) {
            setIsOfflineRequest(true)

            if (chooseCarData) {
              setServiceChooseCarDataAction({
                ...chooseCarData,
                availableOnlineOrder: false,
              })
            }
          }
        } catch (error) {
          console.error(error)

          if (chooseCarData) {
            setServiceChooseCarDataAction({
              ...chooseCarData,
              availableOnlineOrder: false,
            })
          }
        } finally {
          setIsLoading(false)
        }
      })()
    }
  }, [activeProgram, programs])

  useUpdateEffect(() => {
    setFieldValue('program', activeProgram)
  }, [activeProgram])

  return (
    <Component onSubmit={handleSubmit} noValidate autoComplete='off'>
      {isLoading && <Spinner absolute={true} isCabinet={true} />}

      <FormInnerWrapper isLoading={isLoading}>
        <CabinetFormTemplate title={'Выберите услугу'}>
          <FormRow>
            <FormCol>
              <CabinetFormField
                error={
                  errors.program && touched.program
                    ? errors.program.label
                    : undefined
                }
              >
                <CabinetSelect
                  value={activeProgram}
                  options={programs}
                  onChange={setActiveProgram}
                />
              </CabinetFormField>
            </FormCol>
          </FormRow>

          {!isOfflineRequest && programList && (
            <ProgramWrapper>
              <ProgramTable tableType='work'>
                {programList?.works.map((program, index) => (
                  <Program key={index} {...program} />
                ))}
              </ProgramTable>
              <ProgramTable tableType='parts' totalCost={totalCoast}>
                {programList?.parts.map((program, index) => (
                  <Program key={index} {...program} />
                ))}
              </ProgramTable>

              <FormNote>
                <span>*</span> Не является публичной офертой. Стоимость услуг
                рассчитана предварительно и не является окончательной.
              </FormNote>

              <FormNote>
                <span>*</span> Не является публичной офертой. Стоимость услуг
                рассчитана предварительно и не является окончательной.
              </FormNote>
            </ProgramWrapper>
          )}

          <FormAction mobileActionReverse>
            <FormActionCol customWidth={50}>
              <CabinetButton variant={'contained'} onClick={onPreviousStep}>
                Назад
              </CabinetButton>
            </FormActionCol>
            <FormActionCol customWidth={50}>
              <CabinetButton type='submit' variant={'contained'}>
                Далее
              </CabinetButton>
            </FormActionCol>
          </FormAction>
        </CabinetFormTemplate>
      </FormInnerWrapper>
    </Component>
  )
}

export default ServiceChooseProgramTOForm

const Component = styled.form`
  position: relative;
`

const ProgramWrapper = styled.div`
  margin-top: 15px;
  padding: 0 16px;
`
