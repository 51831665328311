import { ApiService } from '@/services/api'
import {
  CabinetCarValuationParams,
  CabinetRequestPartsParams,
  CabinetServiceParams,
} from '@/services/leads/typing'
import { getUtmFromCookie } from '@/utils/cookie'
import { websiteSource } from '@/typings/common'

const request = new ApiService({
  url: process.env.NEXT_PUBLIC_LEADS_SERVICE_URL,
}).getRequest()

export function submitCabinetService(params: CabinetServiceParams) {
  return request.post({
    path: `/atlantm/cabinet/service`,
    body: { ...params, source: websiteSource, utm: getUtmFromCookie() },
  })
}

export function submitCabinetRequestParts(params: CabinetRequestPartsParams) {
  return request.post({
    path: `/atlantm/cabinet/request-parts`,
    body: { ...params, source: websiteSource, utm: getUtmFromCookie() },
  })
}

export function submitCabinetCarValuation(params: CabinetCarValuationParams) {
  return request.post({
    path: `/atlantm/cabinet/car-valuation`,
    body: { ...params, source: websiteSource, utm: getUtmFromCookie() },
  })
}
