import { CarSelectsOptions } from './types'

import { Nullable, OptionType } from '@/typings/common'
import { useProfile } from '@/providers/Profile'
import { getYearsOptions } from '@/utils/date'

export const useBrandsAndModelsOptions = (
  brand: Nullable<OptionType>,
  isUniteZeekr?: boolean,
): Nullable<CarSelectsOptions> => {
  const { brandsAndModels } = useProfile()

  if (!brandsAndModels.data || brandsAndModels.data.length === 0) {
    return null
  }

  let brands: OptionType[]

  if (isUniteZeekr) {
    brands = brandsAndModels.data
      .filter(brand => brand.name !== 'Zeekr CN')
      .map(brand =>
        brand.name === 'Zeekr EU'
          ? {
              label: 'Zeekr',
              value: 'Zeekr',
            }
          : {
              label: brand.name,
              value: brand.name,
            },
      )
  } else {
    brands = brandsAndModels.data.map(({ name }) => ({
      label: name,
      value: name,
    }))
  }

  const targetBrandName = brand?.label === 'Zeekr' ? 'Zeekr EU' : brand?.label

  const models: OptionType[] =
    brandsAndModels.data
      .find(({ name }) => name === targetBrandName)
      ?.models.map(({ name }) => ({ label: name, value: name })) ?? []

  return {
    brands,
    models,
    years: getYearsOptions(),
  }
}
