import moment from 'moment'

import { OFFLINE_REQUEST } from '../ServiceChooseCarForm/constants'

import { useProfile } from '@/providers/Profile'

export const useGetSummary = (): [otherSummary: string[]] => {
  const { service } = useProfile()
  const { chooseCarData, chooseService, chooseCarCenterData, chooseProgramTO } =
    service

  const isOnlineApplication = chooseCarData?.availableOnlineOrder

  const isZeroPrice = chooseProgramTO?.isZeroPrice

  const dateNameField = isOnlineApplication
    ? 'Дата записи'
    : 'Предварительная дата записи'

  const summary = {
    otherSummary: {
      dealer: `Автоцентр: ${chooseCarCenterData?.dealer.label}`,
      date: `${dateNameField}: ${
        chooseCarCenterData
          ? moment(chooseCarCenterData.date).format('D MMMM YYYY') +
            (isOnlineApplication ? ' ' + chooseCarCenterData.time : '')
          : ''
      }`,
      car:
        chooseCarData?.carBrand &&
        `Автомобиль: ${chooseCarData?.carBrandName ?? ''}${
          chooseCarData?.carModel?.value &&
          chooseCarData?.carModel.value !== OFFLINE_REQUEST
            ? ` ${chooseCarData?.carModel.label}`
            : ''
        }${chooseCarData?.carPlate ? ` ${chooseCarData.carPlate}` : ''}`,
      service: `Услуга: ${chooseService?.service.label}`,
      programTO:
        chooseProgramTO?.program.label &&
        `Программа ТО: ${chooseProgramTO?.program.label}`,
      totalCoast:
        !isZeroPrice &&
        chooseProgramTO?.totalCoast &&
        `Стоимость работ: ${chooseProgramTO?.totalCoast}, BYN`,
      totalMinutes:
        !isZeroPrice &&
        chooseProgramTO?.totalMinutes &&
        `Примерное время выполнения работ: ${chooseProgramTO?.totalMinutes}, мин.`,
    },
  }

  return [
    Object.values(summary.otherSummary)
      .filter(Boolean)
      .map(item => String(item)),
  ]
}
