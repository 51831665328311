import * as Yup from 'yup'

import {
  ServiceChooseCarAutoFormFields,
  ServiceChooseCarByHandFormFields,
  ServiceChooseCarFormFields,
} from './types'

import { OFFLINE_REQUEST } from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceChooseCarForm/constants'

export const initialServiceChooseCarFormValues: ServiceChooseCarFormFields = {
  carId: 0,
  carBrand: 0,
  carBrandName: '',
  carVin: '',
  carPlate: '',
  carModel: { value: '', label: '' },
  gearbox: { value: '', label: '' },
  year: { value: '', label: '' },
  engine: { value: '', label: '' },
  wheel: { value: '', label: '' },
  availableOnlineOrder: false,
}

export const validationServiceChooseCarAutoFormSchema: Yup.Schema<ServiceChooseCarAutoFormFields> =
  Yup.object().shape({
    carId: Yup.number().required('Выберите машину').min(1, 'Выберите машину'),
  })

export const validationServiceChooseCarByHandFormSchema = (
  chooseTO: boolean,
): Yup.Schema<ServiceChooseCarByHandFormFields> =>
  Yup.object().shape({
    carBrandName: Yup.string(),
    carPlate: Yup.string(),
    availableOnlineOrder: Yup.boolean(),
    carBrand: Yup.number().required('Выберите бренд').min(1, 'Выберите бренд'),

    carModel: Yup.object({
      label: Yup.string().required('Выберите модель'),
      value: Yup.string().required('Выберите модель'),
    }),
    engine: Yup.object({
      label: Yup.string().default(''),
      value: Yup.string().default(''),
    }).when('carModel.value', {
      is: (modelValue: string) => modelValue !== OFFLINE_REQUEST && chooseTO,
      then: () =>
        Yup.object({
          label: Yup.string().required('Выберите объем и тип двигателя'),
          value: Yup.string().required('Выберите объем и тип двигателя'),
        }),
      otherwise: () => Yup.object().nullable(),
    }),

    year: Yup.object({
      label: Yup.string().default(''),
      value: Yup.string().default(''),
    }).when(['carModel.value', 'engine.value'], {
      is: (modelValue: string, engineValue: string) =>
        modelValue !== OFFLINE_REQUEST &&
        engineValue !== OFFLINE_REQUEST &&
        chooseTO,
      then: () =>
        Yup.object({
          label: Yup.string().required('Выберите год выпуска'),
          value: Yup.string().required('Выберите год выпуска'),
        }),
      otherwise: () => Yup.object().nullable(),
    }),
    gearbox: Yup.object({
      label: Yup.string().default(''),
      value: Yup.string().default(''),
    }).when(['carModel.value', 'engine.value', 'year.value'], {
      is: (modelValue: string, engineValue: string, yearValue: string) =>
        modelValue !== OFFLINE_REQUEST &&
        engineValue !== OFFLINE_REQUEST &&
        yearValue !== OFFLINE_REQUEST &&
        chooseTO,
      then: () =>
        Yup.object({
          label: Yup.string().required('Выберите тип коробки передач'),
          value: Yup.string().required('Выберите тип коробки передач'),
        }),
      otherwise: () => Yup.object().nullable(),
    }),
    wheel: Yup.object({
      label: Yup.string().default(''),
      value: Yup.string().default(''),
    }).when(['carModel.value', 'engine.value', 'year.value', 'gearbox.value'], {
      is: (
        modelValue: string,
        engineValue: string,
        yearValue: string,
        gearboxValue: string,
      ) =>
        modelValue !== OFFLINE_REQUEST &&
        engineValue !== OFFLINE_REQUEST &&
        yearValue !== OFFLINE_REQUEST &&
        gearboxValue !== OFFLINE_REQUEST &&
        chooseTO,
      then: () =>
        Yup.object({
          label: Yup.string().required('Выберите тип привода'),
          value: Yup.string().required('Выберите тип привода'),
        }),
      otherwise: () => Yup.object().nullable(),
    }),
  })
