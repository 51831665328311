import styled from '@emotion/styled'

import { ProgramProps } from './types'

function Program({ name, price, quantity, minutes }: ProgramProps) {
  return (
    <>
      <TableCell>{name}</TableCell>
      {minutes ? <TableCell>{minutes.toFixed(2)}</TableCell> : null}
      {quantity ? <TableCell>{quantity}</TableCell> : null}
      <TableCell>{price?.toFixed(2)}</TableCell>
    </>
  )
}

export default Program

const TableCell = styled.p``
