import styled from '@emotion/styled'

import { ProgramTableProps } from './types'

function ProgramTable({ children, totalCost, tableType }: ProgramTableProps) {
  const header =
    tableType === 'work' ? (
      <Header>
        <TableCell>Работы</TableCell>
        <TableCell>Время, мин</TableCell>
        <TableCell>Стоимость, BYN</TableCell>
      </Header>
    ) : (
      <Header>
        <TableCell>Материалы</TableCell>
        <TableCell>Количество</TableCell>
        <TableCell>Стоимость, BYN</TableCell>
      </Header>
    )

  return (
    <Component>
      {header}

      <Main>{children}</Main>

      {totalCost ? (
        <Footer>
          <Total>Итого</Total>
          <Price>{totalCost}</Price>
        </Footer>
      ) : null}
    </Component>
  )
}

export default ProgramTable

const Component = styled.div`
  padding: 5px;
  border: 1px solid ${props => props.theme.gray400};
`

const Header = styled.div`
  display: grid;
  grid-template-columns: 47% 25% 25%;
  font-weight: bold;
`

const TableCell = styled.p``

const Main = styled.div`
  margin-top: 10px;
  display: grid;
  row-gap: 10px;
  column-gap: 5px;
  grid-template-columns: 47% 25% 25%;
`

const Footer = styled.div`
  display: grid;
  grid-template-columns: 72% 25%;
  font-weight: bold;
  margin-top: 10px;
  column-gap: 10px;
`

const Total = styled.p``

const Price = styled.p``
