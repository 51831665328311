import { ApiService } from '@/services/api'
import {
  CarMaintenanceServicesResponseType,
  RequestServiceParams,
  ServiceAdditionalParametersDrivesParams,
  ServiceAdditionalParametersEngineParams,
  ServiceAdditionalParametersGearboxesParams,
  ServiceAdditionalParametersIntervalParams,
  ServiceAdditionalParametersSelectResponseType,
  ServiceAdditionalParametersYearsParams,
  ServiceBrandLandingResponseType,
  ServiceBrandServiceLandingResponseType,
  ServiceBrandsParams,
  ServiceBrandsResponseType,
  ServiceCarCentersOnlineParams,
  ServiceCarCentersResponseType,
  ServiceDatesAndTimeParams,
  ServiceDatesAndTimeResponseType,
  ServiceDealersResponseType,
  ServiceModelsParams,
  ServiceModelsResponseType,
  ServiceOnlineBrandsResponseType,
  ServiceProgramListParams,
  ServiceProgramListResponseType,
  ServiceSingleLandingResponseType,
} from '@/services/service/typing'
import { getUtmFromCookie } from '@/utils/cookie'

const serviceRequest = new ApiService({
  url: process.env.REACT_APP_SERVICE_URL,
}).getRequest()

export function getServiceDealersData(): Promise<ServiceDealersResponseType[]> {
  return serviceRequest.get({ path: `/dealers` })
}

export function getServiceBrandsData(): Promise<ServiceBrandsResponseType[]> {
  return serviceRequest.get({ path: `/brands` })
}

export function getServiceSingleLandingData(
  serviceAlias: string[],
): Promise<ServiceSingleLandingResponseType> {
  return serviceRequest.get({
    path: `/service/landing`,
    params: { serviceAlias: serviceAlias.join(',') },
  })
}

export function getServiceBrandLandingData(
  brandAlias: string,
): Promise<ServiceBrandLandingResponseType> {
  return serviceRequest.get({ path: `/brand/${brandAlias}/landing` })
}

export function getServiceBrandServiceLandingData(
  brandAlias: string,
  serviceAlias: string[],
): Promise<ServiceBrandServiceLandingResponseType> {
  return serviceRequest.get({
    path: `/brand/service/${brandAlias}/landing`,
    params: { serviceAlias: serviceAlias.join(',') },
  })
}

export function getCarMaintenanceServices(): Promise<
  CarMaintenanceServicesResponseType[]
> {
  return serviceRequest.get({
    path: `/products`,
  })
}

export function getServiceBrands({
  productId,
}: ServiceBrandsParams): Promise<ServiceOnlineBrandsResponseType[]> {
  return serviceRequest.get({
    path: `/products/${productId}/brands`,
  })
}

export function getServiceModels(
  params: ServiceModelsParams,
): Promise<ServiceModelsResponseType[]> {
  return serviceRequest.get({
    path: `/programs/models`,
    params,
  })
}

export function getServiceEngines(
  params: ServiceAdditionalParametersEngineParams,
): Promise<ServiceAdditionalParametersSelectResponseType> {
  return serviceRequest.get({
    path: `/programs/filters`,
    params,
  })
}

export function getServiceYears(
  params: ServiceAdditionalParametersYearsParams,
): Promise<ServiceAdditionalParametersSelectResponseType> {
  return serviceRequest.get({
    path: `/programs/filters`,
    params,
  })
}

export function getServiceGearboxes(
  params: ServiceAdditionalParametersGearboxesParams,
): Promise<ServiceAdditionalParametersSelectResponseType> {
  return serviceRequest.get({
    path: `/programs/filters`,
    params,
  })
}

export function getServiceDrives(
  params: ServiceAdditionalParametersDrivesParams,
): Promise<ServiceAdditionalParametersSelectResponseType> {
  return serviceRequest.get({
    path: `/programs/filters`,
    params,
  })
}

export function getServiceInterval(
  params: ServiceAdditionalParametersIntervalParams,
): Promise<ServiceAdditionalParametersSelectResponseType> {
  return serviceRequest.get({
    path: `/programs/filters`,
    params,
  })
}

export function getProgramList(
  params: ServiceProgramListParams,
): Promise<ServiceProgramListResponseType> {
  return serviceRequest.get({
    path: `/programs/view`,
    params,
  })
}

export function getServiceOnlineBrands(
  params: ServiceCarCentersOnlineParams,
): Promise<ServiceCarCentersResponseType[]> {
  return serviceRequest.get({
    path: `/booking/service-centers`,
    params,
  })
}

export function getServiceOfflineBrands(): Promise<
  ServiceCarCentersResponseType[]
> {
  return serviceRequest.get({
    path: `/booking/service-centers`,
  })
}

export function getServiceDatesAndTime(
  params: ServiceDatesAndTimeParams,
): Promise<ServiceDatesAndTimeResponseType[]> {
  return serviceRequest.get({
    path: `/booking/calendar`,
    params,
  })
}

export function submitCabinetService(body: RequestServiceParams) {
  return serviceRequest.post({
    path: `/booking/request`,
    body: { ...body, source: 'ATLANTM', utm: getUtmFromCookie() },
  })
}
