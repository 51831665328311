import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import moment from 'moment'

import { ServiceFormState, ServiceFormViewProps } from './types'
import ServiceFormView from './components/ServiceFormView'

import { media } from '@/utils/mixin'
import { useProfile } from '@/providers/Profile'
import { getCarById } from '@/modules/PersonalData/utils'
import { scrollToTop } from '@/utils/scroll'
import { stepsData } from '@/modules/Service/constants'
import { ServiceSummaryFormFields } from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceSummaryForm/types'
import { submitCabinetService } from '@/services/service/service-service'

function MainContent() {
  const {
    service,
    setServiceChooseServiceAction,
    setServiceActiveStepAction,
    setServiceChooseCarDataAction,
    setServiceChooseProgramTOAction,
    setServiceChooseCarCenterDataAction,
    setServiceFormSubmitAction,
  } = useProfile()
  const activeFormStep = service.activeStep
  const cars = service.cars
  const carData = service.chooseCarData
  const chooseCarCenter = service.chooseCarCenterData
  const chooseService = service.chooseService
  const chooseProgramTO = service.chooseProgramTO

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmitServiceForm = async (
    summary: ServiceSummaryFormFields,
  ): Promise<void> => {
    const isOnlineRequest = carData?.availableOnlineOrder ?? false

    await submitCabinetService({
      serviceCenter: chooseCarCenter?.dealer.value ?? 0,
      datetime:
        moment(chooseCarCenter?.date).format('YYYY-MM-DD') +
        (isOnlineRequest ? ' ' + chooseCarCenter?.time : ''),

      product: Number(chooseService?.service.value) ?? 0,
      program: chooseService?.service.label,

      firstName: summary.name,
      lastName: summary.surname ?? '',
      phone: summary.phone,

      isOnline: isOnlineRequest,
      minutes: chooseProgramTO?.totalMinutes,

      car: {
        vin: carData?.carVin || undefined,
        brand: carData?.carBrandName ?? '',
        model: carData?.carModel?.label ?? '',
        plate: carData?.carPlate || undefined,
        year: carData?.year.label || undefined,
        mileage: getCarById(carData?.carId ?? 0, cars)?.mileage ?? undefined,
      },

      text: `Бренд: ${carData?.carBrandName ?? ''}, 
             Модель: ${carData?.carModel?.label ?? ''},
             Программа ТО: ${chooseProgramTO?.program.label ?? ''},
             Стоимость: ${chooseProgramTO?.totalCoast ?? ''}BYN,
             Время: ${chooseProgramTO?.totalMinutes ?? ''}мин,
             Комментарий: ${summary.comment ?? ''}`,
      isCallBack: summary.callBack,
    })
  }

  // @ts-ignore
  const props = React.useMemo((): ServiceFormViewProps => {
    switch (activeFormStep) {
      case ServiceFormState.Service: {
        return {
          state: activeFormStep,
          props: {
            onNextStep: values => {
              scrollToTop('auto')
              setServiceChooseServiceAction(values)
              setServiceActiveStepAction(stepsData.steps[1])

              if (chooseService?.service.label !== values.service.label) {
                setServiceChooseCarDataAction(null)
              }
            },
          },
        }
      }

      case ServiceFormState.Car:
        return {
          state: activeFormStep,
          props: {
            onNextStep: values => {
              scrollToTop('auto')
              setServiceChooseCarDataAction(values)

              if (values.availableOnlineOrder) {
                setServiceActiveStepAction(stepsData.steps[2])
              } else {
                setServiceActiveStepAction(stepsData.steps[3])
              }

              if (carData?.carBrandName !== values.carBrandName) {
                setServiceChooseProgramTOAction(null)
                setServiceChooseCarCenterDataAction(null)
              }
            },
            onPreviousStep: () => {
              scrollToTop('auto')
              setServiceActiveStepAction(stepsData.steps[0])
            },
          },
        }

      case ServiceFormState.ProgramTO: {
        return {
          state: activeFormStep,
          props: {
            onNextStep: async values => {
              scrollToTop('auto')
              setServiceChooseProgramTOAction(values)
              setServiceActiveStepAction(stepsData.steps[3])
            },
            onPreviousStep: () => {
              scrollToTop('auto')
              setServiceActiveStepAction(stepsData.steps[1])
            },
          },
        }
      }

      case ServiceFormState.CarCenter:
        return {
          state: activeFormStep,
          props: {
            onNextStep: async values => {
              scrollToTop('auto')
              setServiceChooseCarCenterDataAction(values)
              setServiceActiveStepAction(stepsData.steps[4])
            },
            onPreviousStep: () => {
              scrollToTop('auto')

              if (carData?.availableOnlineOrder) {
                setServiceActiveStepAction(stepsData.steps[2])
              } else {
                setServiceActiveStepAction(stepsData.steps[1])
              }
            },
          },
        }

      case ServiceFormState.Summary:
        return {
          state: activeFormStep,
          props: {
            onNextStep: async values => {
              scrollToTop('auto')
              try {
                await handleSubmitServiceForm(values).then(() =>
                  setServiceFormSubmitAction(true),
                )
              } catch (error) {
                return Promise.reject(error)
              }
            },
            onPreviousStep: () => {
              scrollToTop('auto')
              setServiceActiveStepAction(stepsData.steps[3])
            },
          },
        }
    }
  }, [activeFormStep])

  return (
    <Component>
      <Title>Онлайн-запись на сервис</Title>
      <ServiceFormViewWrapper>
        <ServiceFormView {...props} />
      </ServiceFormViewWrapper>
    </Component>
  )
}

export default MainContent

const Component = styled.div``

const Title = styled.p`
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  text-transform: uppercase;
  color: ${props => props.theme.main};

  ${media.tabletSmall(css`
    font-size: 24px;
  `)}
`

const ServiceFormViewWrapper = styled.div`
  margin-top: 30px;
`
