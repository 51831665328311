import React from 'react'

import { ServiceFormState, ServiceFormViewProps } from '../../types'

import ServiceChooseCarCenterForm from './components/ServiceChooseCarCenterForm'
import ServiceChooseCarForm from './components/ServiceChooseCarForm'

import ServiceChooseServiceForm from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceChooseServiceForm'
import ServiceChooseProgramTOForm from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceChooseProgramTOForm'
import ServiceSummaryForm from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceSummaryForm'

const getContent = (params: ServiceFormViewProps) => {
  switch (params.state) {
    case ServiceFormState.Service:
      return <ServiceChooseServiceForm {...params.props} />

    case ServiceFormState.Car:
      return <ServiceChooseCarForm {...params.props} />

    case ServiceFormState.ProgramTO:
      return <ServiceChooseProgramTOForm {...params.props} />

    case ServiceFormState.CarCenter:
      return <ServiceChooseCarCenterForm {...params.props} />

    case ServiceFormState.Summary:
      return <ServiceSummaryForm {...params.props} />

    default:
      return <div>Default</div>
  }
}

function ServiceFormView(props: ServiceFormViewProps) {
  return getContent(props)
}

export default ServiceFormView
