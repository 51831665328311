import React from 'react'
import styled from '@emotion/styled'

import { SummaryProps } from './types'

function Summary({ data }: SummaryProps) {
  return (
    <Component>
      <SummaryWrapper>
        {data.map((el, index) => (
          <SummaryElement key={index}>{el}</SummaryElement>
        ))}
      </SummaryWrapper>
    </Component>
  )
}

export default Summary

const Component = styled.div``

const SummaryWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 3px;
`

const SummaryElement = styled.p``
