import React, { useLayoutEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Steps from './components/Steps'
import { stepsData } from './constants'
import MainContent from './components/MainContent'
import Success from './components/Success'

import { media } from '@/utils/mixin'
import Cabinet from '@/modules/Cabinet'
import { useProfile } from '@/providers/Profile'
import { useDocumentTitle } from '@/hooks/useDocumentTitle'

function CabinetService() {
  useDocumentTitle('Кабинет Атлант-М - Запись на сервис')
  const {
    service,
    updatePageLoading,
    pageLoading,
    setServiceActiveStepAction,
    setServiceChooseCarDataAction,
    setServiceChooseCarCenterDataAction,
    setServiceFormSubmitAction,
    getBrandsAndModelsAction,
    getServiceCarsAction,
    getOfflineBrandsAction,
  } = useProfile()
  const activeStep = service.activeStep
  const isFormSubmit = service.isServiceFormSubmit

  useLayoutEffect(
    () => {
      setServiceActiveStepAction(stepsData.steps[0])
      setServiceChooseCarDataAction(null)
      setServiceChooseCarCenterDataAction(null)

      if (isFormSubmit) {
        setServiceFormSubmitAction(false)
      }

      async function fetchData() {
        await Promise.all([
          getBrandsAndModelsAction(),
          getServiceCarsAction(),
          getOfflineBrandsAction(),
        ])
      }

      updatePageLoading(true)

      fetchData()
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          updatePageLoading(false)
        })
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <Cabinet activeTab={'SERVICE'}>
      {!pageLoading && (
        <Component>
          <Content isFormSubmit={isFormSubmit}>
            {!isFormSubmit ? (
              <React.Fragment>
                <SideBar>
                  <Steps
                    title={stepsData.title}
                    steps={stepsData.steps}
                    activeStep={activeStep}
                  />
                </SideBar>
                <Main>
                  <MainContent />
                </Main>
              </React.Fragment>
            ) : (
              <Success />
            )}
          </Content>
        </Component>
      )}
    </Cabinet>
  )
}

export default CabinetService

const Component = styled.div`
  padding: 60px 0;

  ${media.tabletSmall(css`
    padding: 30px 0;
  `)}

  ${media.mobile(css`
    padding: 0;
  `)}
`

const Content = styled.div<{ isFormSubmit?: boolean }>`
  display: flex;
  max-width: 990px;
  padding: 40px 60px 60px;
  margin: auto;
  background: ${props => props.theme.white};
  box-shadow:
    0 0 80px 0 #d7e8ff,
    0 0 20px rgb(0 0 0 / 20%);

  ${media.tabletSmall(css`
    flex-direction: column;
    padding: 40px;
  `)}

  ${media.mobile(css`
    padding: 20px;
  `)}
  
  ${({ isFormSubmit }) =>
    isFormSubmit &&
    css`
      justify-content: center;
    `}
`

const SideBar = styled.div`
  width: 40%;

  ${media.tabletSmall(css`
    width: 100%;
  `)}
`

const Main = styled.div`
  width: 60%;
  margin-left: 30px;

  ${media.tabletSmall(css`
    width: 100%;
    margin: 40px 0 0;
  `)}
`
