import * as Yup from 'yup'
import moment from 'moment'

import { ServiceChooseCarCenterFormFields } from './types'

import { Nullable, OptionType } from '@/typings/common'
import {
  ServiceCarCentersResponseType,
  ServiceDatesAndTimeResponseType,
} from '@/services/service/typing'
import { DefaultOption } from '@/modules/PersonalData/utils'

export const getTimeSlots = (
  date: Nullable<Date>,
  datesAndTimes: ServiceDatesAndTimeResponseType[],
): string[] => {
  const dateString = moment(date).format('YYYY-MM-DD')
  const matchedDate = datesAndTimes.find(item => item.date === dateString)

  if (!matchedDate) {
    return []
  }

  return matchedDate.slots
}

export const getExcludedDates = (
  datesAndTimes?: ServiceDatesAndTimeResponseType[],
): Date[] => {
  if (!datesAndTimes) {
    return []
  }

  return datesAndTimes
    .filter(item => item.slots.length === 0)
    .map(item => new Date(item.date))
}

export const getInitialServiceValues = (
  values: Nullable<ServiceChooseCarCenterFormFields>,
): OptionType => {
  if (!values) {
    return DefaultOption
  }

  return { value: String(values.dealer.value), label: values.dealer.label }
}

export const getSelectOptionCarCenter = (
  brands: ServiceCarCentersResponseType[],
): OptionType[] => {
  if (!brands) return [DefaultOption]

  return brands.map(brand => ({
    value: brand.id,
    label: `${brand.name} (${brand.address})`,
  }))
}

export const initialServiceChooseCarCenterFormValues: ServiceChooseCarCenterFormFields =
  {
    dealer: { value: 0, label: '' },
    date: '',
    time: '',
  }

export const validationServiceChooseCarCenterFormSchema = (
  isOnlineServiceDisabled?: boolean,
): Yup.Schema<ServiceChooseCarCenterFormFields> =>
  Yup.object().shape({
    dealer: Yup.object({
      label: Yup.string().required('Выберите автоцентр'),
      value: Yup.number().required('Выберите автоцентр'),
    }),
    date: Yup.string().required('Выберите дату'),
    dealerName: Yup.string(),

    time: isOnlineServiceDisabled
      ? Yup.string().required('Выберите время')
      : Yup.string(),
  })
