import { MenuItemType, Nullable, ThumbnailType } from '@/typings/common'

export const headerMenu: Array<MenuItemType> = [
  {
    id: 4249,
    label: 'Авто в наличии :CARS:',
    link: process.env.REACT_APP_ORIGIN + '/cars',
    isNewTab: false,
    children: [
      {
        id: 4250,
        label: 'Авто в наличии:',
        link: null,
        isNewTab: false,
        children: [
          {
            id: 4251,
            label: 'Все авто',
            link: process.env.REACT_APP_ORIGIN + '/cars',
            isNewTab: false,
            children: [],
          },
          {
            id: 4252,
            label: 'Новые авто',
            link: process.env.REACT_APP_ORIGIN + '/cars/new',
            isNewTab: false,
            children: [],
          },
          {
            id: 4253,
            label: 'Авто с пробегом',
            link: process.env.REACT_APP_ORIGIN + '/cars/amp',
            isNewTab: false,
            children: [],
          },
          {
            id: 4254,
            label: 'Электромобили',
            link: process.env.REACT_APP_ORIGIN + '/cars/electro',
            isNewTab: false,
            children: [],
          },
          {
            id: 4255,
            label: 'Коммерческие',
            link: process.env.REACT_APP_ORIGIN + '/cars/commercial',
            isNewTab: false,
            children: [],
          },
          {
            id: 4256,
            label: 'Суперцены 🔥',
            link: process.env.REACT_APP_ORIGIN + '/cars/filter-super_price',
            isNewTab: false,
            children: [],
          },
          {
            id: 4257,
            label: 'Оценка и выкуп авто',
            link: process.env.REACT_APP_ORIGIN + '/value-car',
            isNewTab: false,
            children: [],
          },
          {
            id: 4200,
            label: 'Помощь в подборе',
            link: process.env.REACT_APP_ORIGIN + '/podbor_auto_s_probegom',
            isNewTab: false,
            children: [],
          },
        ],
      },
      {
        id: 4258,
        label: 'По типу кузова:',
        link: null,
        isNewTab: false,
        children: [
          {
            id: 4260,
            label: 'Кроссовер',
            link: process.env.REACT_APP_ORIGIN + '/cars/filter-body-crossover',
            isNewTab: false,
            children: [],
          },
          {
            id: 4259,
            label: 'Седан',
            link: process.env.REACT_APP_ORIGIN + '/cars/filter-body-sedan',
            isNewTab: false,
            children: [],
          },
          {
            id: 4261,
            label: 'Хэтчбек',
            link:
              process.env.REACT_APP_ORIGIN +
              '/cars/filter-body-crossover-hatchback',
            isNewTab: false,
            children: [],
          },
          {
            id: 4262,
            label: 'Универсал',
            link:
              process.env.REACT_APP_ORIGIN +
              '/cars/filter-body-crossover-station_wagon',
            isNewTab: false,
            children: [],
          },
          {
            id: 4263,
            label: 'Внедорожник',
            link:
              process.env.REACT_APP_ORIGIN + '/cars/filter-body-crossover-suv',
            isNewTab: false,
            children: [],
          },
          {
            id: 4264,
            label: 'Пикап',
            link: process.env.REACT_APP_ORIGIN + '/cars/filter-body-pickup',
            isNewTab: false,
            children: [],
          },
          {
            id: 4265,
            label: 'Микроавтобус',
            link: process.env.REACT_APP_ORIGIN + '/cars/filter-body-minibus',
            isNewTab: false,
            children: [],
          },
          {
            id: 4266,
            label: 'Фургон',
            link:
              process.env.REACT_APP_ORIGIN +
              'https://atlantm.by/cars/filter-body-van',
            isNewTab: false,
            children: [],
          },
          // {
          //   id: 4267,
          //   label: 'Лифтбек',
          //   link: '/',
          //   isNewTab: false,
          //   children: [],
          // },
          // {
          //   id: 4268,
          //   label: 'Минивэн',
          //   link: '/',
          //   isNewTab: false,
          //   children: [],
          // },
          // {
          //   id: 4269,
          //   label: 'Шасси',
          //   link: '/',
          //   isNewTab: false,
          //   children: [],
          // },
          // {
          //   id: 4270,
          //   label: 'Автобус',
          //   link: '/',
          //   isNewTab: false,
          //   children: [],
          // },
          // {
          //   id: 4271,
          //   label: 'Кабриолет',
          //   link: '/',
          //   isNewTab: false,
          //   children: [],
          // },
          // {
          //   id: 4272,
          //   label: 'Купе',
          //   link: '/',
          //   isNewTab: false,
          //   children: [],
          // },
        ],
      },
      {
        id: 4273,
        label: 'По цене:',
        link: null,
        isNewTab: false,
        children: [
          {
            id: 4274,
            label: 'до 50 000 BYN',
            link: process.env.REACT_APP_ORIGIN + '/cars/filter-max_price-50000',
            isNewTab: false,
            children: [],
          },
          {
            id: 4275,
            label: 'от 50 000 BYN до 100 000 BYN',
            link:
              process.env.REACT_APP_ORIGIN +
              '/cars/filter-max_price-100000--min_price-50000',
            isNewTab: false,
            children: [],
          },
          {
            id: 4276,
            label: 'от 100 000 BYN до 200 000 BYN',
            link:
              process.env.REACT_APP_ORIGIN +
              '/cars/filter-max_price-200000--min_price-100000',
            isNewTab: false,
            children: [],
          },
          {
            id: 4277,
            label: 'от 200 000 BYN и выше',
            link:
              process.env.REACT_APP_ORIGIN + '/cars/filter-min_price-200000',
            isNewTab: false,
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 4278,
    label: 'Электромобили',
    link: process.env.REACT_APP_ORIGIN + '/electro',
    isNewTab: false,
    children: [],
  },
  {
    id: 4279,
    label: 'Модельный ряд',
    link: process.env.REACT_APP_ORIGIN + '/catalog/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4284,
    label: 'Сервис :SERVICE:',
    link: process.env.REACT_APP_ORIGIN + '/service',
    isNewTab: false,
    children: [
      {
        id: 4285,
        label: ':SERVICES:',
        link: null,
        isNewTab: false,
        children: [],
      },
      {
        id: 4286,
        label: 'Онлайн запись на сервис :SERVICE_ONLINE:',
        link: '/service',
        isNewTab: false,
        children: [],
      },
    ],
  },
  {
    id: 4287,
    label: 'Оценка и выкуп',
    link: process.env.REACT_APP_ORIGIN + '/value-car/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4288,
    label: 'Акции',
    link: process.env.REACT_APP_ORIGIN + '/offers/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4289,
    label: 'Новости',
    link: process.env.REACT_APP_ORIGIN + '/news/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4290,
    label: 'Обзоры',
    link: process.env.REACT_APP_ORIGIN + '/reviews',
    isNewTab: false,
    children: [],
  },
  {
    id: 4291,
    label: 'Как купить',
    link: process.env.REACT_APP_ORIGIN + '/how-to/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4292,
    label: 'Дилеры',
    link: process.env.REACT_APP_ORIGIN + '/dealers/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4293,
    label: 'Страхование',
    link: process.env.REACT_APP_ORIGIN + '/finservices/insurance/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4294,
    label: 'Бонусная программа',
    link: process.env.REACT_APP_ORIGIN + '/bonus-program/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4295,
    label: 'Аварийный менеджер',
    link: process.env.REACT_APP_ORIGIN + '/caraccidenthelp',
    isNewTab: false,
    children: [],
  },
  {
    id: 4296,
    label: 'Мобильное приложение',
    link: 'https://atlantm.by/application',
    isNewTab: true,
    children: [],
  },
]

export const headerMenuMobile: Array<MenuItemType> = [
  {
    id: 2375,
    label: 'Связаться с нами',
    link: process.env.REACT_APP_ORIGIN + '/dealers/',
    isNewTab: false,
    children: [
      {
        id: 2376,
        label: ':PHONE:',
        link: null,
        isNewTab: false,
        children: [],
      },
      {
        id: 2377,
        label: 'online@favicon-m.by',
        link: 'mailto:online@atlantm-m.by',
        isNewTab: false,
        children: [],
      },
      {
        id: 2378,
        label: 'Заказать звонок',
        link: ':CALL:',
        isNewTab: false,
        children: [],
      },
      {
        id: 2379,
        label: 'Книга отзывов',
        link: 'https://ekov2.atlantm.com/ru/',
        isNewTab: true,
        children: [],
      },
      {
        id: 2380,
        label: 'Пользовательское соглашение',
        link: process.env.REACT_APP_ORIGIN + '/license-agreement/',
        isNewTab: false,
        children: [],
      },
    ],
  },
]

export enum HeaderVariables {
  Brands = ':BRANDS:',
  Banner = ':BANNER:',
  Phone = ':PHONE:',
  Call = ':CALL:',
  Models = ':MODELS:',
  Cars = ':CARS:',
  Service = ':SERVICE:',
  Services = ':SERVICES:',
  ServiceOnline = ':SERVICE_ONLINE:',
}

export const headerServices: Array<{
  section: Nullable<string>
  items: Array<{
    image: Nullable<ThumbnailType>
    name: Nullable<string>
    link: Nullable<string>
    isNewTab: boolean
  }>
}> = [
  {
    section: 'Популярные услуги',
    items: [
      {
        image: {
          url: 'https://dev.atlantm.by/uploads/tmp/fy/zg/fyZGsT0KUP.png',
        },
        name: 'Кузовной ремонт',
        link: process.env.REACT_APP_ORIGIN + '/service/kuzovnoy-remont-all',
        isNewTab: false,
      },
      {
        image: {
          url: 'https://dev.atlantm.by/uploads/tmp/ih/9t/Ih9tnLmq7H.png',
        },
        name: 'Техническое обслуживание',
        link: process.env.REACT_APP_ORIGIN + '/service/tech-obsluzhivanie-auto',
        isNewTab: false,
      },
      {
        image: {
          url: 'https://dev.atlantm.by/uploads/tmp/f4/xl/F4XLVZdmeQ.png',
        },
        name: 'Замена масла в двигателе',
        link:
          process.env.REACT_APP_ORIGIN +
          '/service/tech-obsluzhivanie-auto/zamena-masla-v-dvigatele',
        isNewTab: false,
      },
      {
        image: {
          url: 'https://dev.atlantm.by/uploads/tmp/bi/rd/bIrDfqvCKf.png',
        },
        name: 'Установка дополнительного оборудования',
        link: process.env.REACT_APP_ORIGIN + '/service/dop-oborudovanie',
        isNewTab: false,
      },
      {
        image: {
          url: 'https://dev.atlantm.by/uploads/tmp/xx/ac/xXAcXi8z7s.png',
        },
        name: 'Шиномонтаж',
        link:
          process.env.REACT_APP_ORIGIN +
          '/service/shinomontazh/sezonnyy-shinomontazh',
        isNewTab: false,
      },
      {
        image: {
          url: 'https://dev.atlantm.by/uploads/tmp/tv/jv/tvJvty34Bx.png',
        },
        name: 'Ремонт автоэлектрики',
        link:
          process.env.REACT_APP_ORIGIN +
          '/service/elektrooborudovanie-i-elektronika',
        isNewTab: false,
      },
      {
        image: {
          url: 'https://dev.atlantm.by/uploads/tmp/6q/xz/6qXZSAESV8.png',
        },
        name: 'Компьютерная диагностика двигателя',
        link:
          process.env.REACT_APP_ORIGIN +
          'https://atlantm.by/service/dvigatel-i-ego-sistemy/diagnostika-dvigatelya',
        isNewTab: false,
      },
    ],
  },
  {
    section: 'Продукты',
    items: [
      {
        image: null,
        name: 'Аварийный менеджер',
        link: process.env.REACT_APP_ORIGIN + '/caraccidenthelp',
        isNewTab: false,
      },
      {
        image: null,
        name: 'Бонусная программа',
        link: process.env.REACT_APP_ORIGIN + '/bonus-program',
        isNewTab: false,
      },
      {
        image: null,
        name: 'Мобильное приложение',
        link: process.env.REACT_APP_ORIGIN + '/application',
        isNewTab: false,
      },
      {
        image: null,
        name: 'Личный кабинет',
        link: process.env.REACT_APP_ORIGIN + '/lkabinet',
        isNewTab: false,
      },
      {
        image: null,
        name: 'Акции',
        link:
          process.env.REACT_APP_ORIGIN +
          '/offers?tag=%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81',
        isNewTab: false,
      },
    ],
  },
  // {
  //   section: 'Другие продукты',
  //   items: [
  //     {
  //       image: null,
  //       name: 'Бонусная программа',
  //       link: '/',
  //       isNewTab: false,
  //     },
  //     {
  //       image: null,
  //       name: 'Мобильное приложение',
  //       link: '/',
  //       isNewTab: false,
  //     },
  //     {
  //       image: null,
  //       name: 'Личный кабинет',
  //       link: '/',
  //       isNewTab: false,
  //     },
  //     {
  //       image: null,
  //       name: 'Дилеры',
  //       link: '/',
  //       isNewTab: false,
  //     },
  //   ],
  // },
]
